// @ts-nocheck
/* eslint-disable */

import React from 'react';
import './SectionItem.scss';
import {_SectionItemProps, SectionItem} from '@modules/ContentEngine/components/items/sections/SectionItem';
import SendReminder from '@modules/Team/components/common/SendEmailToTeam/SendReminder';
import {_TeamProfile} from '@modules/Team/types/team.model';

interface _Props extends _SectionItemProps {
  teamId: string;
  answeredProfiles?: string[];
  allProfiles?: Record<string, _TeamProfile>;
  notificationType?: string;
}

export const TeamSectionItem: React.FC<_Props> = ({
  notificationType = 'TA',
  answeredProfiles,
  allProfiles,
  teamId,
  ...props
}) => {
  let answeringCountComponent;
  if (answeredProfiles !== undefined && allProfiles !== undefined) {
    answeringCountComponent = (
      <SendReminder
        teamId={teamId}
        totalCount={Object.keys(allProfiles || {})?.length}
        finishedCount={answeredProfiles?.length}
        notificationType={notificationType}
        recipients={Object.entries(allProfiles || {})
          .filter(([profileId]) => !answeredProfiles?.includes(profileId))
          .map(([_, profile]) => profile.email)}
      />
    );
  }
  return (
    <SectionItem
      {...props}
      teamId={teamId}
      journalSuffix={answeringCountComponent}
      journalMetadata={
        props.journalKey
          ? {
              teamId,
              ...props.journalMetadata,
            }
          : {...props.journalMetadata}
      }
    />
  );
};
