// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {
  ContentEngineContainer,
  ContentEngineJsonContainer,
} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {createJSONFromItems} from '@modules/ContentEngine/util/ceUtil';
import {fireContentEngineDialog} from '@modules/ContentEngine/util/dialogs';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {getIconByName, IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {closeCurrentDialog} from '@modules/Core/hooks/ui/dialog';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {dispatchReactEvent, ReactEventActionClicked, ReactEventJournalUpdated} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {CreateJournalButton} from '@modules/Profile/components/journals/CreateJournalButton';
import UpgradePlanButton from '@modules/Profile/components/payments/UpgradePlanButton';
import {getUpgradeButtonId} from '@modules/Tutorials/util/tutorialIdUtil';

export const Action: React.FC<_UIAction> = ({
  type,
  label,
  link,
  condition,
  onClick,
  onMouseEnter,
  onMouseLeave,
  metadata,
  icon,
  iconPosition,
  width = 'relative-col-width-2',
  origin,
  secondaryType,
  source,
  selectProps,
  fab,
}) => {
  const {navigate} = useAppNavigate();

  let shouldShow = condition === undefined || condition === true;

  // if list,check length
  if (Array.isArray(condition)) {
    // TODO @Sherif not sure about this, not changing to avoid breaking stuff for now
    shouldShow = condition.length > 0;
  }

  const onClickAction = (): void => {
    if (secondaryType === 'close_dialog') {
      closeCurrentDialog();
    }
    if (secondaryType === 'fire_event') {
      dispatchReactEvent(ReactEventActionClicked, {
        ...metadata,
      });
    }

    if (secondaryType === 'ce_dialog_trigger') {
      fireContentEngineDialog(metadata?.key, metadata?.params, {withLoadingIndicator: true}, metadata?.dialogProps);
    }

    if (onClick) {
      onClick();
    } else if (link) {
      navigate(link);
    }
  };

  const onClickJournal = (): void => {
    dispatchReactEvent(ReactEventJournalUpdated);
  };

  const Icon = icon ? getIconByName(icon) : null;

  if (shouldShow) {
    switch (type) {
      case 'select':
        // filter options depending on the "show" boolean field
        const options = (selectProps?.options ?? []).filter((option: any) => option.show !== false);
        return (
          <div className={twMerge(width)}>
            <Select {...(selectProps ?? {})} options={options} />
          </div>
        );
      case 'cta_button':
        if (fab && !!Icon) {
          return (
            <ToolTip txt={label} plc="left">
              <IconButton radius="lg" size="lg" color="brand" icon={Icon} onClick={onClickAction} />
            </ToolTip>
          );
        }
        return (
          <Button
            label={label}
            iconPosition={iconPosition}
            icon={Icon}
            variant="brand"
            onClick={onClickAction}
            width={width}
          />
        );

      case 'icon_button':
        return (
          <IconButton
            label={label}
            iconPosition={iconPosition}
            icon={Icon}
            onClick={onClickAction}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            width={width}
            color="no-background"
          />
        );
      case 'link_button':
        return <TextLink label={label} onClick={onClickAction} />;

      case 'upgrade_button':
        return <UpgradePlanButton source={source} id={getUpgradeButtonId(origin)} label={label} width={width} />;

      case 'secondary_button':
        if (fab && !!Icon) {
          return (
            <ToolTip txt={label} plc="left">
              <IconButton radius="lg" color="no-background" icon={Icon} onClick={onClickAction} />
            </ToolTip>
          );
        }
        return (
          <Button
            label={label}
            iconPosition={iconPosition}
            icon={Icon}
            variant="secondary"
            onClick={onClickAction}
            width={width}
          />
        );

      case 'journal':
        return (
          <CreateJournalButton
            selectedGroupCategory={metadata?.journalCategory as string}
            teamId={metadata?.teamId as string}
            onUpdateJournals={onClickJournal}
            icon={IconAdd}
            width={width}
            size="md"
          />
        );

      case 'content_engine':
        if (!metadata?.key) {
          logger.warn('[Action] contentEngine action is missing key', {metadata});
          return null;
        }
        const params = copyObject(metadata.params) ?? {};
        if (fab) {
          params.noBackground = false;
          params.size = 'md';
        }
        return <ContentEngineContainer type={metadata.key} params={params} />;

      case 'content_engine_item':
        if (!metadata?.item) {
          logger.warn('[Action] contentEngineItem action is missing item', {metadata});
          return null;
        }
        return <ContentEngineJsonContainer jsonContent={createJSONFromItems([metadata.item])} />;

      case 'button':
      default:
        if (fab && !!Icon) {
          return (
            <ToolTip txt={label} plc="left">
              <IconButton radius="lg" size="lg" icon={Icon} onClick={onClickAction} color="fab" />
            </ToolTip>
          );
        }
        return <Button label={label} iconPosition={iconPosition} icon={Icon} onClick={onClickAction} width={width} />;
    }
  }
  return null;
};
