// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SHARED_WITH_OTHERS} from '@modules/ContentEngine/components/items/profile/result/sharedResults/ProfileSharedResultsItem';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {formatDate} from '@modules/Core/util/dates';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {ThirdPersonFeedbackPlaceholder} from '@modules/Profile/components/services/thirdPersonFeedback/ThirdPersonFeedbackPlaceholder';
import {_ThirdPersonFeedbackForOther} from '@modules/Profile/types/third-person-feedback.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ThirdPersonFeedbackTableProps {
  data: _ThirdPersonFeedbackForOther[];
}

export const ThirdPersonFeedbackForOthersTable: React.FC<_ThirdPersonFeedbackTableProps> = ({data}) => {
  const navigateToFlow = useNavigateToFlow();
  const columns: _TableColumns = [
    {label: trans('profile.third_person_feedback.sent_by_header'), key: 'name', width: 'relative-col-width-4-plus-gap'},
    {label: trans('base.date'), key: 'created_at', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.status'), key: 'status', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.action'), key: 'action', width: 'relative-col-width-2'},
  ];

  const renderRow = (row: _ThirdPersonFeedbackForOther): _TableDataItem => {
    let action;
    switch (row.status) {
      case 'DONE':
        action = (
          <TextLink
            label={trans('profile.third_person_feedback.see_self_feedback')}
            dusk="feedback-action-btn"
            onClick={() =>
              navigateToFlow(FLOWS.ThirdPersonFeedback, {
                inviteId: row.id,
                projectId: row.project_id,
                result: true,
                isSelfResult: true,
              })
            }
          />
        );
        break;
      case 'PENDING':
        action = (
          <TextLink
            label={trans('base.start_now')}
            dusk="feedback-action-btn"
            onClick={() =>
              navigateToFlow(FLOWS.ThirdPersonFeedback, {
                projectId: row.project_id,
                inviteId: row.id,
              })
            }
          />
        );
        break;
      case 'ACCEPTED':
        action = (
          <TextLink
            label={trans('dashboard.continue')}
            dusk="feedback-action-btn"
            onClick={() =>
              navigateToFlow(FLOWS.ThirdPersonFeedback, {
                projectId: row.project_id,
                inviteId: row.id,
              })
            }
          />
        );
        break;
    }
    return {
      name: <AvatarUser label={row._sent_by_profile?.fullName} image={getProfilePic(row._sent_by_profile)} />,
      created_at: <Typography>{formatDate(new Date(row.created_at))}</Typography>,
      status: trans(`profile.third_person_feedback.status.${row.status}`),
      action,
    };
  };

  if (!data?.length) {
    return <ThirdPersonFeedbackPlaceholder type={SHARED_WITH_OTHERS} />;
  }
  return (
    <Table
      data={data}
      columns={columns}
      renderRow={renderRow}
      filterOptions={{
        enabled: true,
        searchAttributes: ['profile.fullName', 'created_at', 'type'],
      }}
    />
  );
};
