// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _Badge {
  value: string;
  size?: 'sm' | 'md' | 'lg' | 'fit';
  color?: 'light' | 'dark' | 'brand' | 'success' | 'disabled';
}

export const Badge: React.FC<_Badge> = ({value, size = 'md', color = 'brand'}) => {
  let finSize = 'h-3 w-3';
  if (size === 'sm') finSize = 'h-2 w-2';
  if (size === 'md') finSize = 'h-3 w-3';
  if (size === 'lg') finSize = 'h-4 w-4';
  if (size === 'fit') finSize = 'h-fit w-fit';

  let bgColor = 'bg-brand700';
  if (color === 'disabled') bgColor = 'bg-grey50';
  if (color === 'dark') bgColor = 'bg-blue700';
  if (color === 'light') bgColor = 'bg-white500';
  if (color === 'brand') bgColor = 'bg-brand500';
  if (color === 'success') bgColor = 'bg-green500';

  return (
    <div
      className={addCN(
        'flex flex-col justify-center items-center shrink-0',
        'gap-[0.5rem]',
        bgColor,
        finSize,
        size !== 'fit' && 'rounded-full',
        size === 'fit' && 'rounded-md p-1'
      )}
    >
      <span className="text-caption !text-typography-white">{value}</span>
    </div>
  );
};
