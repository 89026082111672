// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconAdd, IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {Action} from '@modules/Core/components/layout/Action';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import './Fab.css';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import useOnMouseEnter from '@modules/Core/hooks/onMouseEnter';
import {SendNewEmailDialog, showEmailDialog} from '@modules/Core/util/emailDialogHelper';
import {trans} from '@modules/Translations/util/i18n';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';
import {_Dialog} from '../Dialog';

interface _Props {
  actions?: _UIAction[];
  fabHover?: boolean;
}

const id = 'app-fab';
const triggerDistancePx = 0;
const triggerDistancePxOut = 50;

export const Fab: React.FC<_Props> = ({actions, fabHover = false}) => {
  const [isActionsVisible, setIsActionsVisible] = useState(false);
  const handleClickOutside = (): void => {
    setIsActionsVisible(false);
  };
  const ref = useClickOutOfBoundsHelper(handleClickOutside, id);
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});

  useEffect(() => {
    setIsActionsVisible(fabHover);
  }, [fabHover]);

  useOnMouseEnter({
    ref,
    distanceIn: triggerDistancePx,
    distanceOut: triggerDistancePxOut,
    onMouseEnter: () => setIsActionsVisible(true),
    onMouseLeave: () => setIsActionsVisible(false),
  });

  const fabActions = [
    {
      label: trans('base.contact_us'),
      icon: 'email',
      onClick: () =>
        showEmailDialog({
          emailContent: {
            subject: trans('emails.subject.contact_us'),
            body: '',
            recipients: ['support@softfact.works'],
          },
          setDialogConfig,
          onCloseDialog: () => {
            setDialogConfig({open: false});
          },
          withScheduling: false,
        }),
    },
    ...(actions || []),
  ];

  return (
    <>
      <SendNewEmailDialog dialogConfig={dialogConfig} onClose={() => setDialogConfig({open: false})} />

      <div ref={ref} id={id} className="fixed bottom-12 flex flex-col items-end z-[999] ml-[1485px]">
        {/* Render additional action buttons when isActionsVisible is true */}
        <div className="relative h-full w-full min-h-full min-w-full">
          {/* Main FAB button */}
          <div
            className=" bg-blue-600 hover:bg-blue-700 text-white rounded-full shadow-lg fab-trigger"
            style={{
              transform: 'translate(4px, 4px)',
            }}
          >
            {fabActions.map((action, index) => {
              const radius = 60; // Adjust as needed to control spacing
              const angle = index * (Math.PI / 4); // 45 degrees in radians
              const x = Math.cos(angle) * radius;
              const y = Math.sin(angle) * radius;

              return (
                <div
                  key={index}
                  // className="absolute transition-transform duration-300 ease-out fab-action"
                  // className={`absolute fab-trigger fab-action ${isActionsVisible ? 'fab-action--visible' : ''}`}
                  style={{
                    '--x': `${-x}px`,
                    '--y': `${-y}px`,
                  }}
                >
                  <Action fab {...action} />
                </div>
              );
            })}
            {/* <IconButton */}
            {/*  id={getTutorialTriggerId(id)} */}
            {/*  size="lg" */}
            {/*  radius="lg" */}
            {/*  icon={isActionsVisible ? IconCancel : IconAdd} */}
            {/*  active={isActionsVisible} */}
            {/* /> */}
          </div>
        </div>
      </div>
    </>
  );
};
