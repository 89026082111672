// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Carousel} from '@modules/Core/components/base/carousel/Carousel';
import {IconCheckTicked} from '@modules/Core/components/base/Icons/Icons';
import {_TableColumns, Table} from '@modules/Core/components/base/table/Table';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {profileHasTeamDevAnswers} from '@modules/Team/util/serviceUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profiles: Record<string, _TeamProfile>;
  view: 'list' | 'carousel';
  onSelectProfile?: (profileId: string) => void;
  showOnlyAnswered?: boolean;
  showList?: boolean;
}

export const TeamAnswersProfiles: React.FC<_Props> = ({
  showList,
  showOnlyAnswered,
  onSelectProfile,
  view,
  profiles,
}) => {
  const renderAvatarComponent = (profile: _TeamProfile, showLabel = false): React.ReactNode => {
    return (
      <AvatarUser
        size="md"
        onClick={() => onSelectProfile && onSelectProfile(profile.id)}
        image={getProfilePic(profile)}
        label={showLabel ? profile.fullName : undefined}
      />
    );
  };

  // use profileHasTeamDevAnswers
  const filteredProfiles = !showOnlyAnswered
    ? profiles
    : Object.entries(profiles)
        .filter(([profileId, profile]) => profileHasTeamDevAnswers(profile))
        .reduce<Record<string, _TeamProfile>>((acc, [profileId, profile]) => {
          acc[profileId] = profile;
          return acc;
        }, {});

  return view === 'carousel' || !showList ? (
    <CarouselView renderAvatarComponent={renderAvatarComponent} profiles={filteredProfiles} />
  ) : (
    <ListView profiles={filteredProfiles} renderAvatarComponent={renderAvatarComponent} />
  );
};

interface _ViewProps {
  profiles: Record<string, _TeamProfile>;
  renderAvatarComponent: (profile: _TeamProfile, showLabel?: boolean) => React.ReactNode;
}

const ListView = ({profiles, renderAvatarComponent}: _ViewProps) => {
  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'relative-col-width-8-plus-gap'},
    {
      label: trans('development_result.team_answers.list_header_answer_status'),
      key: 'status',
      width: 'relative-col-width-3-plus-gap',
    },
  ];

  const renderRow = (profile: _TeamProfile) => {
    return {
      name: renderAvatarComponent(profile, true),
      status: <div>{profileHasTeamDevAnswers(profile) ? <IconCheckTicked /> : null}</div>,
    };
  };
  return (
    <Table
      data={Object.entries(profiles).map(([profileId, profile]) => ({
        key: profileId,
        ...profile,
      }))}
      renderRow={renderRow}
      columns={columns}
    />
  );
};

const CarouselView = ({profiles, renderAvatarComponent}: _ViewProps): React.ReactNode => {
  return <Carousel renderItem={renderAvatarComponent} items={Object.values(profiles)} limit={20} />;
};
