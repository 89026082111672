// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Fab} from '@modules/Core/components/base/fab/Fab';
import {LatestSoftwareUpdate} from '@modules/Core/components/base/latest-software-update/LatestSoftwareUpdate';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {Action} from '@modules/Core/components/layout/Action';
import {_LayoutOptions, _UIAction} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';
import {useProfile} from '@modules/Profile/hooks/profile';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  setShowSidebar?: (show: boolean) => void;
  title: string | null;
  subTitle: string | null;
  softwareReleaseKey: string | null;
  actions: _UIAction[] | null;
  options: _LayoutOptions | null;
}

export const PageLayoutHeader: React.FC<_Props> = ({
  setShowSidebar,
  title,
  actions,
  subTitle,
  softwareReleaseKey,
  options,
}) => {
  const [softwareDialogOpen, setSoftwareDialogOpen] = useState(false);
  const [fabHover, setFabHover] = useState(false);
  const [currentProfile] = useProfile();

  useEffect(() => {
    logger.debug('[useFlowPageLayout] createOptions', {options});

    if (!options || options.showSidebar === null || options.showSidebar === undefined) {
      return;
    }
    logger.debug('[useFlowPageLayout] createOptions', {options});

    setShowSidebar?.(options.showSidebar);
  }, [options]);

  let subtitleContent = subTitle ? <Typography variant="body1">{subTitle}</Typography> : null;
  if (softwareReleaseKey) {
    subtitleContent = (
      <div className="flex flex-row gap-0">
        {subtitleContent}

        <Typography variant="body1">
          {trans('software_updates.dashboard_title')}{' '}
          <TextLink
            variant="body1"
            onClick={() => {
              setSoftwareDialogOpen(true);
            }}
            label={trans('software_updates.release_notes')}
          />
        </Typography>
      </div>
    );
  }

  logger.debug('[PageLayoutHeader-Component]', {title, actions, softwareReleaseKey, options});

  const endActions = [
    ...(actions?.filter(
      action => (action.position === 'end' || !action.position) && !action.fab && action.type !== 'select'
    ) ?? []),
    // {
    //   uuid: 'fab_trigger',
    //   type: 'icon_button',
    //   label: 'Floating Action Button (See bottom right)',
    //   icon: 'add',
    //   onMouseEnter: () => setFabHover(true),
    //   onMouseLeave: () => setFabHover(false),
    // },
    ...(actions?.filter(
      action => (action.position === 'end' || !action.position) && !action.fab && action.type === 'select'
    ) ?? []),
  ];
  const startActions = actions?.filter(action => action.position === 'start') ?? [];
  const fabActions = actions?.filter(action => action.fab) ?? [];
  return (
    <div className="flex flex-row items-center justify-between w-full gap-3">
      {softwareReleaseKey && (
        <LatestSoftwareUpdate
          asDialog
          latestReleaseKey={softwareReleaseKey}
          open={softwareDialogOpen}
          onClose={() => setSoftwareDialogOpen(false)}
        />
      )}
      <div className="flex flex-row items-center gap-3 flex-grow">
        <div className="flex flex-col gap-2 justify-center items-start h-full">
          {title && <Typography variant="h3">{title}</Typography>}
          {subtitleContent}
        </div>

        {startActions && startActions.length > 0 && (
          <div className="flex flex-row items-center h-full gap-3">
            {startActions.map(action => (
              <Action key={action.uuid} {...action} />
            ))}
          </div>
        )}
      </div>

      <div className="self-end flex flex-row items-center h-full gap-3">
        {endActions.map(action => (
          <Action key={action.uuid} {...action} />
        ))}
      </div>

      {currentProfile && <Fab actions={fabActions} fabHover={fabHover} />}
    </div>
  );
};
