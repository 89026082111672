// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';

export type _ButtonVariants = 'brand' | 'primary' | 'secondary' | 'light';
export type _IconPosition = 'left' | 'right';

export interface _ButtonProps {
  id?: string;
  label?: string;
  variant?: _ButtonVariants;
  disabled?: boolean;
  size?: 'xs' | 'sm';
  width?: string;
  dusk?: string;
  onClick?: (event: any) => void;
  icon?: React.ElementType;
  iconPosition?: _IconPosition;
  iconElement?: React.ReactNode;
  children?: React.ReactNode;
  type?: 'submit' | string | null;

  link?: {
    href: string;
    as?: string;
    target?: string;
    rel?: string;
  };
  asButton?: boolean;
  minified?: boolean;
}

export const Button: React.FC<_ButtonProps> = ({
  id,
  label,
  variant = 'primary',
  disabled,
  size,
  dusk,
  width = 'w-full',
  onClick,
  link,
  children,
  type = null,
  icon: Icon,
  iconElement = null,
  iconPosition = 'left',
  asButton,
  minified = false,
}) => {
  const [hover, setHover] = useState(false);

  let buttonColors = 'bg-blue700 hover:bg-blue500 border-blue700 hover:border-blue500';
  let textColor = '!text-typography-white';
  let iconColor = 'fill-typography-white';
  let borderClasses = 'border-[2px] rounded-xxl';

  if (variant === 'brand') {
    buttonColors = 'bg-brand700 hover:bg-brand500 border-brand700 hover:border-brand500';
    textColor = '!text-typography-white';
    iconColor = 'fill-typography-white';
  }

  if (variant === 'secondary') {
    buttonColors = 'bg-transparent border-blue700 hover:border-blue300';
    textColor = hover ? 'text-blue300' : 'text-blue700';
    iconColor = hover ? 'fill-blue300' : 'fill-blue700';
  }

  if (variant === 'light') {
    buttonColors = 'border-0 bg-white500 hover:bg-grey100';
    textColor = 'text-grey700';
    iconColor = 'fill-grey700';
    borderClasses = 'rounded-xs';
  }

  if (disabled) {
    buttonColors = 'bg-grey500 border-grey500 hover:border-grey500';
    textColor = '!text-typography-white';
    iconColor = 'fill-typography-white';
  }

  let height = 'h-6';
  if (size === 'xs') height = 'h-4';
  if (size === 'sm') height = 'h-5';

  const handleClick = (event: any): void => {
    if (onClick && !disabled) onClick(event);
  };

  // decides if the button acts as a link or a button
  const buttonProps = {
    dusk,
    onMouseEnter: (): void => setHover(true),
    onMouseLeave: (): void => setHover(false),
    className: addCN(
      'w-full transition flex justify-center items-center gap-1',
      borderClasses,
      buttonColors,
      height,
      disabled ? 'disabled' : '',
      iconPosition === 'right' ? 'flex-row-reverse' : 'flex-row',
      minified ? 'px-1 py-1' : 'px-5 py-2.5 '
    ),
    ...(link?.href
      ? {
          href: link.href,
          target: link.target ?? '_blank', // Default to _blank if not specified
          rel: link.rel ?? 'noopener noreferrer', // Default to noopener noreferrer if not specified
        }
      : {
          onClick: handleClick,
        }),
  };

  const content = (
    <>
      {Icon ? <Icon color={iconColor} /> : iconElement}
      {(label || children) && <span className={addCN(textColor, 'whitespace-nowrap')}>{label ?? children}</span>}
    </>
  );

  if (type === 'submit') {
    return (
      <div id={id} className={addCN(width)}>
        <button type="submit" disabled={disabled} {...buttonProps}>
          {content}
        </button>
      </div>
    );
  }

  return (
    <div id={id} className={addCN(width)}>
      {/* careful, the button and the global grid may interfere. relative-col-width can't be set directly on the button since the button also sets its own margin */}
      {/* -> use the relative-col-width on a wrapper */}
      {asButton ? (
        <button type={type} {...buttonProps}>
          {content}
        </button>
      ) : (
        <a {...buttonProps}>{content}</a>
      )}
    </div>
  );
};
