// @ts-nocheck
/* eslint-disable */

import {_ShortProfile} from '@modules/Profile/types/profile.model';

export enum _CandidateAnalysisSubmissionStatus {
  NOT_STARTED = 'not_started',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
}
export enum _CandidateAnalysisStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  CLOSED = 'closed',
}
export interface _CandidateAnalysis {
  id: string;
  title: string;
  description?: string;
  status: _CandidateAnalysisStatus;
  created_at: string;
  createdBy: _ShortProfile;
  metadata: Record<string, any>;

  completedCount?: number;
  canSeeResults?: boolean;
  link?: string;
}

export interface _CandidateAnalysisSubmission {
  id: string;
  candidate_analysis_id: string;
  email: string;
  status: _CandidateAnalysisSubmissionStatus;
  started_at: string | null;
  completed_at: string | null;
  created_at: string;
  updated_at: string;
  answers: Record<string, any>;
}
