// @ts-nocheck
/* eslint-disable */

import React, {FC, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconExpandLess, IconExpandMore} from '@modules/Core/components/base/Icons/Icons';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import '../cards/RoleCard.scss';
import {twMerge} from 'tailwind-merge';

export interface _AccordionProps {
  title?: string;
  titleSuffix?: React.ReactNode;
  titlePrefix?: React.ReactNode;
  titleContent?: React.ReactNode;
  subtitle?: string;
  titleVariant?: _TypographyVariants;
  children?: React.ReactNode;
  expandIconVariant?: 'light' | 'outline' | 'dark' | 'outline-light' | 'brand' | 'no-background';
  expandIconActive?: boolean;
  expanded?: boolean;
  allowExpand?: boolean;
  onTitleClick?: () => void;
}

export const Accordion: FC<_AccordionProps> = ({
  title,
  titleVariant = 'h6',
  subtitle,
  children,
  titleSuffix,
  titlePrefix,
  titleContent,
  expandIconVariant,
  expandIconActive,
  allowExpand = true,
  expanded: initialExpanded,
  onTitleClick,
}) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  return (
    <div
      onClick={onTitleClick}
      className={twMerge(
        'flex px-3 py-2 flex-col items-start gap-3 rounded-sm border-1 border-grey300 bg-white500 w-full',
        onTitleClick ? 'hover:bg-grey100  cursor-pointer' : ''
      )}
    >
      {/* header */}
      <div className={twMerge('flex flex-row items-center justify-between w-full gap-3')}>
        {titleContent && <div className="flex-grow">{titleContent}</div>}
        {!titleContent && (
          <>
            {titlePrefix && <div className="flex">{titlePrefix}</div>}
            <div className="flex flex-col gap-3">
              {title && <Typography variant={titleVariant}>{title}</Typography>}
              {subtitle && <Typography variant="caption">{subtitle}</Typography>}
            </div>
            {titleSuffix && <div className="flex-grow flex justify-end">{titleSuffix}</div>}
          </>
        )}
        {allowExpand && (
          <IconButton onClick={(): void => setExpanded(!expanded)} icon={expanded ? IconExpandLess : IconExpandMore} />
        )}
      </div>

      {Boolean(expanded) && children}
    </div>
  );
};
