// @ts-nocheck
/* eslint-disable */

import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {closeDialog, showDialog} from '@modules/Core/hooks/ui/dialog';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

export function fireConfirmationDialog(dialogProps: _GlobalDialog = {}): void {
  const dialogId = showDialog({
    confirmLabel: trans('base.yes'),
    cancelLabel: trans('base.no'),
    showCloseButton: true,
    onClose: () => {
      closeDialog(dialogId);
    },
    confirmProps: {
      size: 'xs',
    },
    cancelProps: {
      size: 'xs',
    },
    justifyContent: 'center',
    justifyButtons: 'center',
    justifyTitle: 'center',
    ...dialogProps,
  });
}
