// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {boolean} from 'yup';
import {EmailOverviewPlaceholder} from '@modules/ContentEngine/components/items/general/emails/EmailOverviewPlaceholder';
import {EmailsOverviewCard} from '@modules/ContentEngine/components/items/general/emails/EmailsOverviewCard';
import {_Tab, TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_ScheduledEmail, _ScheduledEmailStatus} from '@modules/Profile/types/emails.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  emails: Record<_ScheduledEmailStatus, _ScheduledEmail[]>;
  initialTab?: _ScheduledEmailStatus;
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

const EmailsList = ({emails, setActiveTab}: {emails: _ScheduledEmail[]; setActiveTab: any}) => {
  if (!emails?.length) return <EmailOverviewPlaceholder />;
  return (
    <Section>
      {emails?.map(email => <EmailsOverviewCard onChangeTab={setActiveTab} key={email.id} email={email} />)}
    </Section>
  );
};

const tabsOrder = ['draft', 'scheduled', 'sent'];
export const EmailsOverviewItem: React.FC<_Props> = ({
  context,
  tutorialKey,
  tutorialService,
  initialTab = 'draft',
  emails,
}) => {
  const [activeTab, setActiveTab] = useState<_ScheduledEmailStatus>(initialTab);
  const initialTabLoaded = useRef<boolean>(false);
  const tabs: _Tab[] = [
    {
      name: 'draft',
      title: trans('emails.status.draft'),
      content: <EmailsList emails={emails?.draft ?? []} setActiveTab={setActiveTab} />,
    },
    {
      name: 'scheduled',
      title: trans('emails.status.scheduled'),
      content: <EmailsList emails={emails?.scheduled ?? []} setActiveTab={setActiveTab} />,
    },
    {
      name: 'sent',
      title: trans('emails.status.sent'),
      content: <EmailsList emails={emails?.sent ?? []} setActiveTab={setActiveTab} />,
    },
  ];

  useEffect(() => {
    if (!initialTab && !initialTabLoaded.current) {
      setActiveTab('draft');
      initialTabLoaded.current = true;
    }
  }, [emails, initialTab]);

  logger.debug('[CustomSurveysOverviewItem] surveys', {
    activeTab,
    activeValue: tabsOrder.indexOf(activeTab),
  });
  return (
    <TabsItem
      value={tabsOrder.indexOf(activeTab)}
      setValue={(value: number) => setActiveTab(tabsOrder[value] as _ScheduledEmailStatus)}
      tabs={tabs}
      activeTab={tabsOrder.indexOf(activeTab)}
      context={context}
      tutorialKey={tutorialKey}
      tutorialService={tutorialService}
    />
  );
};
