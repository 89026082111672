// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_CandidateAnalysis} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';

interface _Props {
  analysis: _CandidateAnalysis;
  profileId?: string;
}

export const AnswerCandidateAnalysisItem: React.FC<_Props> = ({profileId, analysis}) => {
  logger.debug('AnswerCandidateAnalysis', {analysis, profileId});

  if (!analysis) {
    return 'TODO FOR STEFANO: placeholder missing for CA not found';
  }

  return (
    <Section>
      <Typography>CA Title: {analysis.title}</Typography>
      <Typography>CA Description: {analysis.description}</Typography>
      TODO FOR STEFANO: This is where we implement what the CA process looks like If this needs to be a survey that uses
      our engine, change this step to a SurveyStep in the AnswerCandidateAnalysisFlow.json
    </Section>
  );
};
