// @ts-nocheck
/* eslint-disable */

import {combineReducers, configureStore, EnhancedStore} from '@reduxjs/toolkit';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cookiesSlice from '@modules/Core/state/cookiesSlice';
import errorSlice from '@modules/Core/state/errorSlice';
import featureFlagsSlice from '@modules/Core/state/featureFlagsSlice';
import notificationsSlice from '@modules/Core/state/notificationsSlice';
import pageLayoutSlice from '@modules/Core/state/pageLayoutSlice';
import rolesAndPermissionsSlice from '@modules/Core/state/rolesAndPermissionsSlice';
import themeSlice from '@modules/Core/state/themeSlice';
import flowJsonSlice from '@modules/FlowEngine/state/flowJsonSlice';
import flowSlice from '@modules/FlowEngine/state/flowSlice';
import institutionSlice from '@modules/Institution/state/InstitutionSlice';
import exercisesSlice from '@modules/Profile/state/exercisesSlice';
import profileSlice from '@modules/Profile/state/profileSlice';
import teamSlice from '@modules/Team/state/TeamSlice';
import teamsSlice from '@modules/Team/state/TeamsSlice';
import trackingSlice from '@modules/Tracking/state/TrackingSlice';
import i18nSlice from '@modules/Translations/state/i18nSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader', 'pageLayout', 'featureFlags'],
};

const combinedReducers = combineReducers({
  // Core
  theme: themeSlice.reducer,
  notifications: notificationsSlice.reducer,
  errors: errorSlice.reducer,
  cookies: cookiesSlice.reducer,
  rolesAndPermissions: rolesAndPermissionsSlice.reducer,
  // Flows
  flow: flowSlice.reducer,
  flowJson: flowJsonSlice.reducer,
  // Lang
  i18n: i18nSlice.reducer,

  // Profile
  profile: profileSlice.reducer,
  exercises: exercisesSlice.reducer,

  // Institution
  institution: institutionSlice.reducer,
  team: teamSlice.reducer,
  teams: teamsSlice.reducer,

  // Platform
  pageLayout: pageLayoutSlice.reducer,
  // team: teamReducer,
  // teams: teamsReducer,
  // errors: errorReducer,
  // cookies: cookiesReducer,
  featureFlags: featureFlagsSlice.reducer,
  tracking: trackingSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store: EnhancedStore<any> = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// Export the store to be used in legacy code
// @ts-expect-error
// window.store = store;
