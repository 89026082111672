// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  _ProfileFeedbackItemProps,
  ProfileFeedbackItem,
} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import SurveyCreator from '@modules/CustomSurveys/components/creator/SurveyCreator';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';
import {SurveyJSJson} from '@modules/CustomSurveys/types/surveyJs.model';
import {mapBackendToSurveyCreator, mapSurveyCreatorToBackend} from '@modules/CustomSurveys/util/customSurveyMapperUtil';
import {saveSurveyAction} from '@modules/CustomSurveys/util/customSurveysFlowActions';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  survey?: _CustomSurvey | undefined | null;
  surveyId?: boolean;
  feedbackProps: _ProfileFeedbackItemProps;
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

export const SurveyCreatorItem: React.FC<_Props> = ({
  surveyId: externalId = true,
  survey: externalSurvey = undefined,
  feedbackProps = {},
  context,
  tutorialKey,
  tutorialService,
}) => {
  const snackbar = useSnackbar();
  const {currentLanguage} = useLanguage();
  const navigateToFlow = useNavigateToFlow();
  const [survey, setSurvey] = useState<_CustomSurvey | null | undefined>(undefined);
  const [surveyId, setSurveyId] = useState<string | null | undefined>(undefined);
  const initialLoaded = useRef(false);
  const changed = useRef(false);

  useEffect(() => {
    setSurveyId(externalId);
  }, [externalId]);
  useEffect(() => {
    if (!initialLoaded.current && externalSurvey !== undefined) {
      setSurvey(externalSurvey);
      setSurveyId(externalSurvey?.id);
      initialLoaded.current = true;
    }
  }, [externalSurvey]);
  const onSave = useCallback(async (): Promise<void> => {
    if (!survey) {
      return;
    }

    const errors = validateSurvey();
    if (errors?.length) {
      snackbar.danger(trans(errors[0]));
    }
    const newSurveyId = await saveSurveyAction(survey, survey?.id);

    logger.debug('Survey ID', surveyId);
    if (newSurveyId) {
      snackbar.success(trans('surveys.save_success'));
      changed.current = false;
      setSurvey({...survey, id: newSurveyId});
      setSurveyId(newSurveyId);

      if (!survey?.id) {
        navigateToFlow('CustomSurvey', {customSurveyId: newSurveyId});
      }
    }
  }, [survey]);

  const validateSurvey = (): string[] | undefined => {
    // Check title available
    if (!survey?.title) {
      return ['surveys.errors.title_missing'];
    }
    // TODO: add mor needed validation depending on design team requirements
  };

  const onChange = (json: SurveyJSJson, surveyId?: string): void => {
    const newSurvey = mapSurveyCreatorToBackend(json);
    logger.debug('Survey JSON', {
      json,
      newSurvey,
      id: surveyId,
    });

    setSurvey({...newSurvey, id: surveyId});
    changed.current = true;
  };

  return (
    <Section>
      <SurveyCreator
        context={context}
        tutorialService={tutorialService}
        tutorialKey={tutorialKey}
        surveyId={surveyId}
        existingSurvey={survey !== undefined ? mapBackendToSurveyCreator(survey, currentLanguage) : undefined}
        onChange={onChange}
        language={currentLanguage}
      />

      <div className="flex flex-row justify-end items-center w-full">
        <Button disabled={!changed.current || !survey} width="relative-col-width-2" onClick={onSave}>
          {trans('base.save')}
        </Button>
      </div>
    </Section>
  );
};
