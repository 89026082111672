// @ts-nocheck
/* eslint-disable */

import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {_CustomSurvey} from '../types/customSurvey.model';

export function shareCustomSurveyFlow(survey: _CustomSurvey): void {
  fireDialogFlow(
    'ShareCustomSurvey',
    null,
    {
      hideButtons: true,
      showCloseButton: true,
      size: 'lg',
    },
    {
      surveyId: survey.id,
      surveyName: survey.title.default ?? survey.title.en ?? survey.title.de ?? '',
      surveyLink: survey.link,
    }
  );
}
