// @ts-nocheck
/* eslint-disable */

import {FC, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {_Alert, Alert} from '@modules/Core/components/base/Alert';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {_GlobalAlert, useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';

interface _Props {
  mainAlert?: boolean;
}

export const GlobalAlert: FC<_Props> = () => {
  const globalAlerts = useUiStore(state => state.globalAlerts);

  const handleClose = (id: string): void => zustandApi.removeFromGlobals('globalAlerts', id);

  const handleShow = (currentAlert: _GlobalAlert): void => {
    if (currentAlert.scope) {
      return;
    }
    zustandApi.updateGlobal('globalAlerts', currentAlert.id, {
      ...currentAlert,
      scope: 'main',
    });
  };

  return (
    <div className={twMerge('fixed left-0 bottom-[39px] flex flex-col gap-1', 'w-full', 'z-[1001]')}>
      {globalAlerts
        .sort((a: _GlobalAlert, b: _GlobalAlert) => (a.id < b.id ? 1 : -1))
        .map((currentAlert: _GlobalAlert) => (
          <Alert
            key={currentAlert.id}
            endIcon
            {...currentAlert}
            onClose={() => handleClose(currentAlert.id)}
            onShow={() => handleShow(currentAlert)}
            width="w-1/2"
          />
        ))}
    </div>
  );
};
