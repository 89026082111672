// @ts-nocheck
/* eslint-disable */

import {JSX} from 'react';
import RegisteredValidators from '@modules/Core/util/forms/registerdValidators';
import {logger} from '@modules/Core/util/Logger';
import {kebabize} from '@modules/Core/util/strings';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {replaceParams} from '@modules/Translations/util/i18n';
import {_FlowInput} from '../../types/core.model';
import {_BaseStepComponentOptions, _FlowStep} from '../../types/step.model';

const registeredValidators = RegisteredValidators;

export function extractStepValidators(
  schemaValidation: Record<string, any>,
  componentClass: Record<string, any>,
  fieldName: string
): any[] {
  let validation = schemaValidation;
  if (!schemaValidation) {
    validation = componentClass?.stepConfig?.validation;
  }

  if (!validation) {
    return [];
  }

  const validationNames = validation[fieldName] as Record<string, any>;

  if (!validationNames) {
    return [];
  }

  const validators = [];

  for (const validationName of Object.keys(validationNames)) {
    const RegisteredValidator = registeredValidators[validationName];
    const options = validationNames[validationName];

    if (!RegisteredValidator) {
      logger.error(`Validator ${validationName} is not registered`);
      continue;
    }

    logger.info(`Adding validator ${validationName} with options ${options}`);

    validators.push(new RegisteredValidator(validationNames[validationName], options));
  }

  return validators;
}

export function getStepParameter(
  options: _BaseStepComponentOptions,
  componentConfig: Record<string, any>,
  fieldName: string
): any {
  const defaultOptions = componentConfig.stepConfig.componentOptions;
  if (options?.[fieldName] !== null && options[fieldName] !== undefined) {
    return options[fieldName];
  }
  return defaultOptions[fieldName];
}

export function getStepParameterHtml(
  options: _BaseStepComponentOptions,
  componentConfig: Record<string, any>,
  fieldName: string,
  params: Record<string, any> = {}
): JSX.Element | string {
  const defaultOptions = componentConfig?.stepConfig?.componentOptions || {};
  if (options?.[fieldName] !== null && options[fieldName] !== undefined) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceParams(options[fieldName], params),
        }}
      />
    );
  }
  return defaultOptions[fieldName];
}

export function getStepByIndex(steps: Record<string, _FlowStep>, index: number): _FlowStep | null {
  for (const step of Object.values(steps)) {
    if (step.index === index) {
      return step;
    }
  }
  return null;
}

export function extractFlowInput(searchVariables: URLSearchParams, teamId = null): Record<string, any> {
  const input = searchVariables;

  if (!searchVariables) {
    if (teamId) {
      return {teamId};
    }
    return {};
  }

  const filteredInput: Record<string, any> = {};

  // filter input to remove undefined values or empty keys
  input?.forEach((value, key) => {
    if (key !== undefined && key !== '') {
      filteredInput[key] = input.get(key);
    }
  });

  if (teamId) {
    filteredInput.teamId = teamId;
  }

  return filteredInput;
}

export function createPathFromFlowInput(flowInput?: _FlowInput): string {
  if (!flowInput) {
    return '';
  }

  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(flowInput)) {
    if (value !== undefined && value !== null) {
      params.append(key, String(value));
    }
  }

  return params.toString();
}

export function flowPath(flowName: string, path: string, kebabized = false): string {
  flowName = FLOWS[flowName];
  const kebabizedFlowName = kebabized ? flowName : kebabize(flowName);
  return `/flow/${kebabizedFlowName}/${path}`;
}
