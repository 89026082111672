// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ScheduledEmailStatus} from '@modules/Core/types/email.model';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

export interface EmailContent {
  subject?: string;
  body?: string;
  recipients?: string[];
  actionLink?: string;
  actionText?: string;
}

interface ShowEmailDialogParams {
  emailContent: EmailContent;
  setDialogConfig: (config: _Dialog & {children?: React.ReactElement}) => void;
  onCloseDialog?: (status?: _ScheduledEmailStatus) => void;
  availableEmails?: string[];
  withScheduling?: boolean;
}

interface _DialogProps {
  dialogConfig: _Dialog;
  onClose?: () => void;
}

export const SendNewEmailDialog: React.FC<_DialogProps> = ({dialogConfig, onClose}) => (
  <Dialog
    open={dialogConfig?.open}
    onClose={onClose}
    title={dialogConfig?.title}
    onConfirm={dialogConfig?.onConfirm}
    cancelLabel={dialogConfig?.cancelLabel}
    confirmLabel={dialogConfig?.confirmLabel}
    showCloseButton
    size={dialogConfig?.size}
  >
    {dialogConfig?.children}
  </Dialog>
);

export function showEmailDialog({
  emailContent,
  setDialogConfig,
  onCloseDialog,
  availableEmails,
  withScheduling = true,
}: ShowEmailDialogParams) {
  setDialogConfig({
    open: true,
    size: 'lg',
    title: trans('emails.send_new_email'),
    children: (
      <SendNewEmailDialogBody
        profile={currentProfile() ?? undefined}
        subject={emailContent?.subject ?? ''}
        body={emailContent?.body ?? ''}
        recipients={emailContent?.recipients ?? []}
        actionLink={emailContent?.actionLink ?? ''}
        actionText={emailContent?.actionText ?? ''}
        status="draft"
        onClosed={onCloseDialog}
        availableEmails={availableEmails}
        readOnly
        withScheduling={withScheduling}
      />
    ),
  });
}
