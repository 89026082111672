// @ts-nocheck
/* eslint-disable */

import {logger} from '@sentry/utils';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_ScheduledEmail} from '@modules/Profile/types/emails.model';

// Define the interface for the email scheduling parameters
export interface ScheduleEmailParams {
  id?: string;
  recipients: string[];
  subject: string;
  body: string;
  actionLink?: string;
  actionText?: string;
  scheduledFor?: string; // ISO date string
  profileId?: string; // Optional if handled by backend
  institutionId?: string; // Optional if handled by backend
}

export interface SaveScheduledEmailDraftParams {
  id?: string;
  recipients: string[];
  subject: string;
  body: string;
  actionLink?: string;
  actionText?: string;
  scheduledFor?: string; // ISO date string
}

export const saveScheduledEmailDraftAction = async (
  params: SaveScheduledEmailDraftParams
): Promise<_ScheduledEmail | null> => {
  const method = 'email.draft.save';

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return responseData.scheduledInvite as _ScheduledEmail;
    }

    logger.error('Save Scheduled Email Draft Action failed:', responseData?.message || responseData?.error);
    return null;
  } catch (e) {
    logger.error('Save Scheduled Email Draft Action encountered an error:', e);
    return null;
  }
};

// Function to schedule or send an email
export const scheduleEmailAction = async (params: ScheduleEmailParams): Promise<boolean> => {
  const method = 'email.schedule';

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      logger.debug('Schedule Email Action response:', responseData.data);
      return true;
    }

    logger.error('Schedule Email Action failed:', responseData?.message || responseData?.error);
    return false;
  } catch (e) {
    logger.error('Schedule Email Action encountered an error:', e);
    return false;
  }
};

// Function to send a scheduled email immediately
// Changed function signature to accept all optional fields of _ScheduledEmail
export const sendScheduledEmailAction = async (
  id: string,
  emailData: Partial<_ScheduledEmail> = {}
): Promise<boolean> => {
  const method = 'email.scheduled.send';
  const params = {
    id,
    ...emailData,
  };

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return true;
    }
    logger.error('Send Scheduled Email Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Send Scheduled Email Action encountered an error:', e);
    return false;
  }
};

// Function to edit a scheduled email
export const editScheduledEmailAction = async (
  id: string,
  fields: Partial<_ScheduledEmail>
): Promise<_ScheduledEmail | null> => {
  const method = 'email.scheduled.edit';
  const params = {id, ...fields};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return responseData.data as _ScheduledEmail;
    }
    logger.error('Edit Scheduled Email Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Edit Scheduled Email Action encountered an error:', e);
    return null;
  }
};

// Function to delete a scheduled email
export const deleteScheduledEmailAction = async (id: string): Promise<boolean> => {
  const method = 'email.scheduled.delete';
  const params = {id};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return true;
    }
    logger.error('Delete Scheduled Email Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Delete Scheduled Email Action encountered an error:', e);
    return false;
  }
};

// Function to save a scheduled email (create or update)
export const saveScheduledEmailAction = async (
  scheduledEmail: Partial<_ScheduledEmail>,
  id?: string
): Promise<_ScheduledEmail | null> => {
  const method = 'email.scheduled.save';
  const params = {id, ...scheduledEmail};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return responseData.data as _ScheduledEmail;
    }
    logger.error('Save Scheduled Email Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Save Scheduled Email Action encountered an error:', e);
    return null;
  }
};

// Function to fetch a scheduled email by ID
export const fetchScheduledEmailAction = async (id: string): Promise<_ScheduledEmail | null> => {
  const method = 'email.scheduled.fetch';
  const params = {id};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS) {
      return responseData.data as _ScheduledEmail;
    }
    logger.error('Fetch Scheduled Email Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Fetch Scheduled Email Action encountered an error:', e);
    return null;
  }
};
