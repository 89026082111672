// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Indicator} from '@modules/Core/components/base/Indicator';
import {HowAreYouSmiley} from '@modules/Core/components/base/lottie/HowAreYouSmiley';
import {Typography} from '@modules/Core/components/base/Typography';
import {getImage, getStatisticImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {StatisticTypeContent} from '@modules/Statistics/components/sections/types/StatisticSectionContent';
import {EXPERIENCE_GAIN_METRIC, HOW_ARE_YOU_METRIC, USER_ACTIVITY_METRIC} from '@modules/Statistics/config/constants';
import {statisticsColors, statisticTitleLangProps} from '@modules/Team/config/statisticsConstants';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  data: {
    name: string;
    subtitle: string;
    metrics?: Record<string, _ProfileTrackingStatisticEntry>;
  };
  view?: 'card' | 'expanded';
  className?: string;
  renderAvatar: () => React.ReactNode;
  renderProgressBar?: () => React.ReactNode;
  supportedMetrics: string[];
  onClick?: () => void;
}

const activityImages: Record<string, string> = {
  high: 'gamification/activity/high_activity.png',
  medium: 'gamification/activity/mid_activity.png',
  low: 'gamification/activity/low_activity.png',
};

export const OverviewCard: React.FC<_Props> = ({
  onClick,
  supportedMetrics,
  className,
  view = 'card',
  data,
  renderAvatar,
  renderProgressBar,
}) => {
  const {name, subtitle, metrics} = data;

  const userActivity = metrics?.[USER_ACTIVITY_METRIC] ?? ({} as _ProfileTrackingStatisticEntry);
  const experienceGained = metrics?.[EXPERIENCE_GAIN_METRIC] ?? ({} as _ProfileTrackingStatisticEntry);
  const howAreYou = metrics?.[HOW_ARE_YOU_METRIC] ?? ({} as _ProfileTrackingStatisticEntry);
  const {trend, classification} = userActivity;
  const showHeader = !!classification || !!trend;

  logger.debug('[TeamTest]TeamIndividualPerformanceCard', {data, trend, experienceGained, howAreYou});
  const showHowAreYou = !!howAreYou?.count;
  const content = (
    <>
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row gap-1 justify-start items-center w-full">
          <div className={twMerge('flex flex-row gap-0 items-end', !experienceGained ? 'hidden' : '')}>
            <img className="h-2 w-2" src={getStatisticImage('experiencelogo')} />
            <Typography variant="body2">{experienceGained?.sum ?? 0}</Typography>
          </div>
          <img
            className={twMerge('h-2 w-2', !classification ? 'hidden' : '')}
            src={getImage(activityImages[classification])}
          />

          {showHowAreYou && <HowAreYouSmiley index={Math.round(howAreYou?.average)} />}
        </div>

        <div className="flex flex-row flex-grow justify-end">
          <StatisticLabelBadge keepInDom change={trend} changeType={trend > 0 ? '+' : trend < 0 ? '-' : 'N'} />
        </div>
      </div>
      <div className="flex flex-row gap-1 w-full">
        {renderAvatar()}
        <div className="flex flex-col gap-0 w-full">
          <Typography bold variant="body">
            {name}
          </Typography>

          <Typography variant="body2">{subtitle}</Typography>
        </div>
      </div>

      {renderProgressBar && renderProgressBar()}
    </>
  );

  if (view === 'card') {
    return (
      <Card onClick={onClick} padding={2} gap={2} className={className}>
        {content}
      </Card>
    );
  }

  logger.debug('[TeamTest]TeamIndividualPerformanceCard', {metrics});

  return (
    <div className={twMerge('flex p-3 flex-col justify-center items-start gap-2 h-full', className)}>
      {content}

      {
        // Object.entries(metrics ?? {})
        // ?.filter(([metricName]) => supportedMetrics.includes(metricName))
        // ?.sort(
        //   ([metricNameA], [metricNameB]) =>
        //     supportedMetrics.indexOf(metricNameA) - supportedMetrics.indexOf(metricNameB)
        // )
        supportedMetrics.map((supportedMetricName, index) => {
          const metricName = supportedMetricName;
          const metric = data?.metrics?.[metricName] ?? {};

          return (
            <ExpandedViewRow
              type={metric?.type}
              key={metricName}
              title={trans(statisticTitleLangProps[metricName])}
              color={statisticsColors[index % statisticsColors.length]}
              value={metric?.sum ?? 0}
            />
          );
        })
      }
    </div>
  );
};

const ExpandedViewRow: React.FC<{type: string; title: string; color: string; value: number}> = ({
  title,
  color,
  value,
  type,
}) => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row items-center w-full gap-1">
        <Indicator size="sm" color={color} />
        <Typography variant="body">{title}</Typography>
      </div>
      <Typography variant="body2">
        <StatisticTypeContent type={type} latestValue={value} />
      </Typography>
    </div>
  );
};
