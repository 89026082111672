// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {navigateToFlow} from '@/_core/util/util';
import {_ProfileTrackingStatisticEntry} from '@modules/ContentEngine/types/metrics.model';
import {Accordion} from '@modules/Core/components/base/accordion/Accordion';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {IconEmail} from '@modules/Core/components/base/Icons/Icons';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import {SERVICE_PROFILE_ROLES} from '@modules/Core/config/services';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {sendReminder} from '@modules/Core/util/reminderHelper';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {useExercises} from '@modules/Profile/hooks/exercises';
import {
  _ExerciseData,
  _ProfileExerciseStatus,
  EXERCISE_STATUS_DONE,
  EXERCISE_STATUS_LOCKED,
  EXERCISE_STATUS_OPEN,
  EXERCISE_STATUS_STARTED,
} from '@modules/Profile/types/exercises.model';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {currentProfile, getProfilePic, getSurveyFlow, isOldSoftfact} from '@modules/Profile/util/profileUtil';
import {refreshReduxState} from '@modules/State/util/util';
import SendReminder from '@modules/Team/components/common/SendEmailToTeam/SendReminder';
import {statusLangProps} from '@modules/Team/components/dashboard/TeamSoftfactsRow';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  allTeams?: Record<string, {profileIds: string[]} & _ShortTeam>;
  allProfiles: Record<string, _ShortProfile>;
  teamId: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  service: string;
  profiles?: _ShortProfile[]; // Made optional since we might not have profiles
  xpMetric: _ProfileTrackingStatisticEntry;
  roundIndex: number;
  type?: 'teams' | 'profiles' | 'single_profile';
  data: {
    progress: number;
    initialSurveyStatus: 'done' | 'open';
    rounds: Array<{
      surveyStatus: 'done' | 'open';
      status: _ProfileExerciseStatus;
      progress: number;
      exercises: Array<{
        profiles?: Array<{
          status: _ProfileExerciseStatus;
          profile: _ShortProfile;
        }>;
        teams?: Array<{
          status: _ProfileExerciseStatus;
          team: _ShortTeam;
        }>;
        status: _ProfileExerciseStatus;
        progress: number;
      }>;
    }>;
  };
}

export const TeamSoftfactStatusDialog: React.FC<_Props> = ({
  roundIndex: initialRoundIndex,
  teamId,
  open,
  setOpen,
  data,
  xpMetric,
  service,
  type = 'profiles', // Default to 'profiles' if not specified
  allProfiles,
  allTeams,
}) => {
  const exercisesData = useExercises();
  const [dialogConfig, setDialogConfig] = React.useState<_Dialog>(null);
  const snackbar = useSnackbar();

  logger.debug('TeamSoftfactsRow', {service, xpMetric, data, exercisesData, initialRoundIndex});

  async function handleSendReminder(profileOrTeamIds: string[]) {
    if (type === 'single_profile') {
      return;
    }

    let finalProfileIds = type === 'profiles' ? profileOrTeamIds : undefined;

    if (finalProfileIds === undefined) {
      // If profileIds is undefined, then it must be teams, get profileIds for each team from allTeams
      finalProfileIds = [];
      profileOrTeamIds.forEach(teamId => {
        finalProfileIds.push(...(allTeams[teamId]?.profileIds ?? []));
      });
      finalProfileIds = Array.from(new Set(finalProfileIds));
    }

    await sendReminder({
      teamId,
      service,
      profileIds: finalProfileIds,
      setDialogConfig,
      onCloseDialog,
      snackbar,
    });
  }
  const getExercisesData = (service: string, roundIndex: number | string, exIndex: number): _ExerciseData => {
    const serviceExercises = exercisesData[service] ?? null;

    if (!serviceExercises) {
      return {};
    }

    return serviceExercises.exercises?.[roundIndex]?.[exIndex] ?? null;
  };

  const onCloseDialog = () => {
    setDialogConfig(null);
    setOpen?.(false);
  };

  return (
    <Dialog
      size="lg"
      showCloseButton
      open={open}
      onClose={onCloseDialog}
      title={trans(PROFILE_SERVICES_TITLES[service])}
      {...(dialogConfig ?? {})}
      contentGap={2}
    >
      {!dialogConfig?.children ? (
        <>
          <AccordionComponent
            titleVariant="h6"
            title={trans('profile_modules.initial_survey')}
            participants={
              type === 'single_profile'
                ? null
                : Object.keys(data.initialSurveyStatus ?? {}).map((profileId, index) => {
                    const status = data.initialSurveyStatus?.[profileId];
                    return {
                      profile: allProfiles?.[profileId] ?? null,
                      status,
                      team: allTeams?.[profileId] ?? null,
                    };
                  })
            }
            service={service}
            type={type}
            sendReminder={handleSendReminder}
            showReminder={
              type === 'single_profile'
                ? null
                : Object.values(data.initialSurveyStatus ?? {}).includes(EXERCISE_STATUS_OPEN)
            }
            finishedCount={
              Object.values(data.initialSurveyStatus ?? {}).filter(status => status === EXERCISE_STATUS_DONE).length
            }
            totalCount={Object.values(data.initialSurveyStatus ?? {}).length}
            status={data.initialSurveyStatus}
          />
          {data?.rounds
            ?.filter(round =>
              [EXERCISE_STATUS_OPEN, EXERCISE_STATUS_STARTED, EXERCISE_STATUS_DONE].includes(round.status)
            )
            ?.map((roundData, roundIndex) => {
              // array of profile ids
              const surveyStatuses = roundData?.surveyStatus;
              return (
                <Accordion
                  key={roundIndex}
                  expanded={roundIndex === initialRoundIndex}
                  title={trans('profile_exercises.round', {
                    roundIndex: Number(roundIndex) + 1,
                  })}
                  titleSuffix={
                    <div className="flex flex-row w-full items-center gap-3 justify-end">
                      <div className="relative-col-width-1">
                        <ProgressBar value={roundData?.progress} color="success" size="xs" />
                      </div>
                      {!!xpMetric?.trend && (
                        <StatisticLabelBadge
                          change={xpMetric?.trend}
                          changeType={xpMetric?.trend > 0 ? '+' : xpMetric?.trend < 0 ? '-' : 'N'}
                        />
                      )}
                    </div>
                  }
                >
                  <div className="flex flex-col gap-2  w-full">
                    {roundData?.exercises.map((exerciseData, exIndex) => {
                      const exerciseProperties = getExercisesData(service, roundIndex, exIndex);
                      const status = exerciseData?.status;

                      // Depending on the type, use profiles or teams
                      const participants =
                        type === 'profiles' ? exerciseData.profiles : type === 'teams' ? exerciseData.teams : null;

                      // Calculate finished and total counts
                      const finishedCount = participants?.filter(
                        participant => participant.status === EXERCISE_STATUS_DONE
                      ).length;
                      const totalCount = participants?.filter(
                        participant => participant.status !== EXERCISE_STATUS_LOCKED
                      )?.length;

                      if (type === 'single_profile' && status === EXERCISE_STATUS_LOCKED) {
                        return null;
                      }
                      return (
                        <AccordionComponent
                          exIndex={exIndex}
                          roundIndex={roundIndex}
                          title={trans('profile_exercises.exercise', {
                            exerciseIndex: exIndex + 1,
                          })}
                          subtitle={
                            exerciseProperties?.intro?.title ? trans(exerciseProperties?.intro?.title ?? '') : undefined
                          }
                          participants={participants}
                          service={service}
                          type={type}
                          sendReminder={handleSendReminder}
                          showReminder={status === EXERCISE_STATUS_STARTED}
                          finishedCount={finishedCount}
                          totalCount={totalCount}
                          status={exerciseData?.status}
                        />
                      );
                    })}

                    {Boolean(roundIndex === 0 || (!isOldSoftfact(service) && service !== SERVICE_PROFILE_ROLES)) && (
                      <AccordionComponent
                        boldTitle
                        title={trans('profile_modules.survey_progress')}
                        participants={
                          type === 'single_profile'
                            ? null
                            : Object.keys(surveyStatuses ?? {})?.map(profileId => ({
                                profile: allProfiles?.[profileId] ?? null,
                                status: surveyStatuses[profileId],
                                team: allTeams?.[profileId] ?? null,
                              }))
                        }
                        status={surveyStatuses}
                        service={service}
                        type={type}
                        sendReminder={handleSendReminder}
                        showReminder={Object.values(surveyStatuses ?? {}).includes(EXERCISE_STATUS_OPEN)}
                        finishedCount={
                          Object.values(surveyStatuses ?? {}).filter(status => status === EXERCISE_STATUS_DONE).length
                        }
                        totalCount={Object.values(surveyStatuses ?? {}).length}
                      />
                    )}
                  </div>
                </Accordion>
              );
            })}
        </>
      ) : (
        dialogConfig.children
      )}
    </Dialog>
  );
};

interface AccordionComponentProps {
  title: string;
  subtitle?: string;
  showReminder?: boolean;
  participants: Array<{
    status: _ProfileExerciseStatus;
    profile: _ShortProfile;
  }>;
  finishedCount?: number;
  totalCount?: number;
  service: string;
  type: 'profiles' | 'teams';
  sendReminder: (profileIds: string[]) => void;
  boldTitle?: boolean;
  // used onle for 'single_profile'
  status?: _ProfileExerciseStatus;
  exIndex?: number;
  roundIndex?: number;
  titleVariant?: _TypographyVariants;
}
const AccordionComponent: React.FC<AccordionComponentProps> = ({
  title,
  subtitle,
  showReminder,
  participants,
  finishedCount,
  totalCount,
  service,
  type,
  sendReminder,
  boldTitle,
  status,
  exIndex,
  roundIndex,
  titleVariant = 'body',
}) => {
  const getParticipantId = (participant: {profile: _ShortProfile; team: _ShortTeam}) => {
    return type === 'profiles' ? participant.profile.id : participant.team.id;
  };
  logger.debug('AccordionComponent', {
    title,
    subtitle,
    showReminder,
    participants,
    finishedCount,
    totalCount,
    service,
    type,
    sendReminder,
    boldTitle,
    status,
  });
  const hasOnClick = type === 'single_profile' && status !== EXERCISE_STATUS_LOCKED;
  return (
    <Accordion
      titleVariant={titleVariant}
      onTitleClick={
        hasOnClick
          ? () => {
              if (roundIndex !== undefined && exIndex !== undefined) {
                navigateToFlow('ProfileExercises', {service, round: roundIndex, exerciseIndex: exIndex});
              } else {
                navigateToFlow(getSurveyFlow(service).name);
              }
            }
          : undefined
      }
      allowExpand={type !== 'single_profile'}
      titleContent={
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col justify-center items-start gap-0 flex-grow">
            <Typography variant={titleVariant} bold={boldTitle}>
              {title}
            </Typography>
            {subtitle && <Typography variant="body">{subtitle}</Typography>}
          </div>
          <div className="flex flex-row items-center gap-2">
            {(showReminder || type === 'single_profile') && (
              <>
                {(finishedCount ?? 0) < (totalCount ?? 0) && type !== 'single_profile' && (
                  <ToolTip txt={trans('base.send_reminder')}>
                    <Button
                      minified
                      variant="light"
                      size="xs"
                      icon={IconEmail}
                      onClick={() => {
                        void sendReminder(
                          participants
                            ?.filter(p => p.status !== EXERCISE_STATUS_DONE)
                            .map(p => {
                              return getParticipantId(p);
                            }) ?? []
                        );
                      }}
                    />
                  </ToolTip>
                )}
                {type !== 'single_profile' ? (
                  <SendReminder
                    finishedCount={finishedCount}
                    totalCount={totalCount}
                    notificationType={`sf_${service}`}
                  />
                ) : (
                  <Typography
                    variant="body"
                    className={`${
                      status === EXERCISE_STATUS_STARTED
                        ? 'text-orange700'
                        : status === EXERCISE_STATUS_DONE
                          ? 'text-green700'
                          : 'text-grey500'
                    } relative-col-width-1-5 text-right`}
                  >
                    {trans(statusLangProps[status] ?? statusLangProps.default)}
                  </Typography>
                )}
              </>
            )}
          </div>
        </div>
      }
    >
      {type !== 'single_profile' && (
        <div className="flex flex-col w-full h-full gap-2">
          {participants?.map(participant => {
            const participantStatus = participant.status;

            if (participantStatus === EXERCISE_STATUS_LOCKED) {
              return null;
            }
            return (
              <div
                key={participant?.profile?.id || participant?.team?.id}
                className="flex flex-row w-full items-center justify-between"
              >
                {type === 'profiles' ? (
                  // For profiles, render AvatarUser
                  <AvatarUser label={participant?.profile?.fullName} image={getProfilePic(participant?.profile)} />
                ) : (
                  // For teams, render placeholder
                  <div>{participant?.team?.name}</div>
                )}
                <div className="flex flex-row items-center gap-2 flex-grow justify-end text-right">
                  {![EXERCISE_STATUS_LOCKED].includes(participantStatus) && (
                    <div className="relative-col-width-1-5 text-right flex items-center justify-end gap-2">
                      {![EXERCISE_STATUS_DONE, EXERCISE_STATUS_LOCKED].includes(participantStatus) && (
                        <div className="flex items-center">
                          <ToolTip txt={trans('base.send_reminder')}>
                            <Button
                              minified
                              variant="light"
                              size="xs"
                              icon={IconEmail}
                              onClick={() => {
                                void sendReminder([getParticipantId(participant)] ?? []);
                              }}
                            />
                          </ToolTip>
                        </div>
                      )}
                      <Typography
                        variant="body"
                        className={`${
                          participantStatus === EXERCISE_STATUS_STARTED
                            ? 'text-orange700'
                            : participantStatus === EXERCISE_STATUS_DONE
                              ? 'text-green700'
                              : 'text-grey500'
                        } flex`}
                      >
                        {trans(statusLangProps[participantStatus] ?? statusLangProps.default)}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Accordion>
  );
};
