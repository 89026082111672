// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CandidateAnalysisOverviewCard} from '@modules/CandidateAnalysis/components/CandidateAnalysisOverviewCard';
import {CandidateAnalysisOverviewPlaceholder} from '@modules/CandidateAnalysis/components/CandidateAnalysisOverviewPlaceholder';
import {_CandidateAnalysis, _CandidateAnalysisStatus} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {_Tab, TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  analyses: Record<_CandidateAnalysisStatus, _CandidateAnalysis[]>;
  initialTab?: 'draft' | 'published';
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

const AnalysesList = ({statusAnalyses, setActiveTab}: {statusAnalyses: _CandidateAnalysis[]; setActiveTab: any}) => {
  if (!statusAnalyses?.length) return <CandidateAnalysisOverviewPlaceholder />;
  return (
    <Section>
      {statusAnalyses?.map(survey => (
        <CandidateAnalysisOverviewCard onChangeTab={setActiveTab} key={survey.id} analysis={survey} />
      ))}
    </Section>
  );
};

const tabsOrder = ['draft', 'published'];
export const CandidateAnalysisOverviewItem: React.FC<_Props> = ({
  context,
  tutorialKey,
  tutorialService,
  initialTab = 'draft',
  analyses,
}) => {
  const [activeTab, setActiveTab] = useState<'draft' | 'published'>(initialTab);

  const tabs: _Tab[] = [
    {
      name: 'draft',
      title: trans('surveys.status.draft'),
      content: <AnalysesList statusAnalyses={analyses?.draft ?? []} setActiveTab={setActiveTab} />,
    },
    {
      name: 'published',
      title: trans('surveys.status.published'),
      content: <AnalysesList statusAnalyses={analyses?.published ?? []} setActiveTab={setActiveTab} />,
    },
  ];

  useEffect(() => {
    if (!initialTab) {
      setActiveTab(analyses?.published ? 'published' : 'draft');
    }
  }, [analyses, initialTab]);

  logger.debug('[CandidateAnalysisOverview] analyses', analyses);
  return (
    <TabsItem
      value={tabsOrder.indexOf(activeTab)}
      setValue={(value: number) => setActiveTab(tabsOrder[value] as 'draft' | 'published')}
      tabs={tabs}
      activeTab={tabsOrder.indexOf(activeTab)}
      context={context}
      tutorialKey={tutorialKey}
      tutorialService={tutorialService}
    />
  );
};
