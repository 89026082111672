// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Indicator} from '@modules/Core/components/base/Indicator';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {Typography} from '@modules/Core/components/base/Typography';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {_Profile} from '@modules/Profile/types/profile.model';
import {TeamDevelopmentProgressBar} from '@modules/Team/components/team_development/common/TeamDevelopmentProgressBar';
import {getTeamDevModuleIcon} from '@modules/Team/util/serviceUtil';
import {trans} from '@modules/Translations/util/i18n';

interface ModuleData {
  status: string;
  progress: string;
}

interface _TeamDevelopmentOverviewTableProps {
  teamId: string;
  progressData: Record<string, ModuleData>;
  profiles: Record<string, _Profile>;
  preview?: boolean;
  withHeaders?: boolean;
}

export const TeamDevelopmentOverviewTable: React.FC<_TeamDevelopmentOverviewTableProps> = ({
  profiles,
  teamId,
  progressData,
  preview,
  withHeaders = true,
}) => {
  const navigateToFlow = useNavigateToFlow();

  const columns: _TableColumns = [
    {label: trans('profile.module'), key: 'module'},
    {label: trans('base.status'), key: 'status'},
    {label: trans('base.team_progress'), key: 'team_progress'},
    {label: '', key: 'actions', width: 'w-[10%]'},
  ];

  const [chosenProfiles, setChosenProfiles] = useState<_Profile[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const renderRow = (moduleData: ModuleData): _TableDataItem => {
    if (!moduleData) return {} as _TableDataItem;
    const Icon = getTeamDevModuleIcon(moduleData.key);
    return {
      module: (
        <div className="flex flex-row items-center gap-1 relative-col-width-3">
          {Icon && <Icon />}
          <Typography>{trans(`profile.${moduleData.key}`)}</Typography>
        </div>
      ),
      status: <div className="relative-col-width-2">{statusComponent(moduleData)}</div>,
      team_progress: <div className="relative-col-width-2">{progressComponent(moduleData)}</div>,
      actions: buttonsComponent(moduleData),
    };
  };

  function getState(moduleData) {
    const state = moduleData?.subModules?.moduleState;
    if (state) {
      return state;
    }

    return null;
  }

  function onGoToModule(module: string, seeResult?: boolean): void {
    if (!seeResult) {
      navigateToFlow(FLOWS.TeamDevelopment, {teamId, module});
      return;
    }
    navigateToFlow(FLOWS.TeamDevelopment, {
      teamId,
      module,
      seeResult,
    });
  }

  function createProgressData(moduleData) {
    const progress = moduleData?.subModules?.progress;
    if (progress) {
      const moduleProgress = {};

      for (const profileId in progress?.progress) {
        moduleProgress[profileId] = progress?.progress?.[profileId];
      }

      return {progress: moduleProgress};
    }
  }

  function statusComponent(moduleData) {
    const state = getState(moduleData);
    let color;
    let text;

    if (!state) {
      return <></>;
    }
    if (state === 'done') {
      text = trans('base.done');
      color = 'success';
    }

    if (state === 'todo') {
      text = trans('base.pending');
      color = 'warning';
    }

    if (state === 'locked') {
      text = trans('base.locked');
      color = 'danger';
    }

    return (
      <div className="flex flex-row items-center gap-1">
        <Indicator color={color} />
        <Typography>{text}</Typography>
      </div>
    );

    return <></>;
  }

  function progressComponent(moduleData) {
    if (!moduleData) {
      return <></>;
    }

    return (
      <TeamDevelopmentProgressBar
        profiles={profiles}
        module={createProgressData(moduleData)}
        avatarHeight={30}
        avatarWidth={30}
        chosenProfiles={chosenProfiles}
        setChosenProfiles={setChosenProfiles}
        showModal={openModal}
        setShowModal={setOpenModal}
      />
    );
  }

  function buttonsComponent(moduleData) {
    const moduleName = moduleData?.key as string;
    const state = getState(moduleData);

    const buttons = [];

    if (state === 'todo') {
      if (!preview) {
        buttons.push(
          <Button size="sm" onClick={() => onGoToModule(moduleName)} variant="primary" dusk="open-btn">
            {trans('base.open')}
          </Button>
        );
      }

      buttons.push(
        <Button size="sm" variant="secondary" dusk="result-btn" onClick={() => onGoToModule(moduleName, true)}>
          {trans('base.result')}
        </Button>
      );
    }

    if (state === 'done') {
      if (!preview) {
        buttons.push(
          <Button size="sm" variant="primary" onClick={() => onGoToModule(moduleName)} dusk="open-btn">
            {trans('base.open')}
          </Button>
        );
      }

      buttons.push(
        <Button size="sm" variant="secondary" dusk="result-btn" onClick={() => onGoToModule(moduleName, true)}>
          {trans('base.result')}
        </Button>
      );
    }

    return (
      <div className="flex flex-row gap-1 items-center">
        {buttons.map(b => (
          <div>{b}</div>
        ))}
      </div>
    );
  }

  return (
    <Table
      data={Object.entries(progressData || {}).map(([moduleName, moduleData]) => ({
        key: moduleName,
        ...moduleData,
      }))}
      renderRow={renderRow}
      columns={columns}
      withHeaders={withHeaders}
    />
  );
};
