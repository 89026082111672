// @ts-nocheck
/* eslint-disable */

import React, {createRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {Chip} from '@modules/Core/components/base/Chip';
import {IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

interface _Props {
  emails: string[];
  setEmails?: (emails: string[]) => void;
  helpLabel?: string;
  consentLabel?: string;
  placeholder?: string;
  label?: string;
  errorEmails?: Record<string, any>;
  disabled?: boolean;
  autocompleteEmails?: string[];
  readOnly?: boolean;
}

export const Emails: React.FC<_Props> = ({
  emails,
  setEmails,
  helpLabel = trans('invite.email_description'),
  consentLabel,
  placeholder,
  label,
  errorEmails,
  disabled,
  autocompleteEmails,
  readOnly,
}) => {
  const ref = createRef<HTMLInputElement>();
  const [emailError, setEmailError] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);

  function hasError(email: string): boolean {
    if (Object.keys(errorEmails ?? {})?.length > 0) {
      return Object.keys(errorEmails ?? {})?.findIndex(e => e === email) > -1;
    }
    return false;
  }

  const suggestions = autocompleteEmails
    ? autocompleteEmails.filter(
        email => email.toLowerCase().includes(inputValue.toLowerCase()) && !emails.includes(email)
      )
    : [];

  const handleAddChip = (
    event: React.KeyboardEvent<HTMLInputElement> | React.ClipboardEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    if (disabled) {
      return;
    }
    const emailString: string =
      event.type === 'paste'
        ? (event as React.ClipboardEvent<HTMLInputElement>).clipboardData.getData('text')
        : (event.target as HTMLInputElement).value;
    const emailList = emailString.split(/[\s,]+/);
    if (
      ['paste', 'blur'].includes(event.type) ||
      ['Enter', 'Space', ' ', ',', 'Tab'].includes((event as React.KeyboardEvent<HTMLInputElement>).key)
    ) {
      event.preventDefault();

      if (emailString === '') {
        return;
      }

      if (autocompleteEmails) {
        // Only accept emails from autocompleteEmails
        if (autocompleteEmails.includes(emailString)) {
          setEmails?.(emails ? [...emails, emailString] : [emailString]);
          setInputValue('');
          setShowSuggestions(false);
          setEmailError(null);
        } else {
          setEmailError(trans('emails.email_not_in_team'));
        }
      } else {
        // Existing behavior
        const filteredEmails = emailList
          .map(email => {
            if (emails.includes(email)) {
              setEmailError(trans('validation.form_validation.duplicate_email'));
              return null;
            }
            const trimmedEmail = email.trim();
            if (trimmedEmail === '') {
              return null;
            }
            return checkEmail(trimmedEmail) ? trimmedEmail : null;
          })
          .filter(email => email !== null);
        if (filteredEmails.length > 0) {
          setEmails([...emails, ...filteredEmails]);
          (event.target as HTMLInputElement).value = '';
          setTimeout(() => {
            (event.target as HTMLInputElement).value = '';
            setInputValue('');
          });
        }
      }
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    if (autocompleteEmails) {
      setShowSuggestions(true);
    }
  };

  const handleSuggestionClick = (email: string) => {
    setEmails?.(emails ? [...emails, email] : [email]);
    setInputValue('');
    setShowSuggestions(false);
    setEmailError(null);
  };

  const handleDeleteChip = (email: string) => {
    setEmails?.(emails ? emails.filter((c: string) => c !== email) : []);
  };

  function checkEmail(email: string): boolean {
    if (emailRegex.test(email.trim())) {
      setEmailError(null);
      return true;
    }
    setEmailError(trans('validation.form_validation.email'));
    return false;
  }

  return (
    <Section fullWidth gap={2}>
      <div className="relative">
        {readOnly && <div className="text-body2-bold user-select-none pointer-events-none">{label}</div>}
        {!readOnly && (
          <Input
            disabled={disabled}
            elRef={ref}
            value={inputValue}
            label={label ?? ''}
            placeholder={placeholder ?? ''}
            onKeyDown={handleAddChip}
            onChange={handleInputChange}
            onBlur={handleAddChip}
            onPaste={handleAddChip as (event: React.ClipboardEvent<HTMLInputElement>) => void}
            helpLabel={helpLabel}
          />
        )}

        {showSuggestions && suggestions.length > 0 && (
          <div className="absolute z-[10000] flex flex-col items-start rounded-b-sm border-1 border-grey300 overflow-hidden shadow-2 bg-white500 w-full">
            {suggestions.map(email => (
              <ListItem
                key={email}
                label={email}
                onChange={() => {
                  handleSuggestionClick(email);
                }}
              />
            ))}
          </div>
        )}
      </div>

      {emails.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {(emails || []).map(email => {
            const emailHasError = hasError(email);

            const chipComponent = (
              <Chip size="sm" key={email} label={email} icon={IconCancel} onClick={() => handleDeleteChip(email)} />
            );

            if (emailHasError) {
              return (
                <ToolTip key={`${email}_tooltip`} txt={errorEmails?.[email]?.message} plc="top">
                  {chipComponent}
                </ToolTip>
              );
            }
            return chipComponent;
          })}
        </div>
      )}

      {emailError && (
        <Typography variant="body" className="text-typography-danger">
          {emailError}
        </Typography>
      )}

      {consentLabel && (
        <Typography variant="caption">
          <div dangerouslySetInnerHTML={{__html: consentLabel}} />
        </Typography>
      )}
    </Section>
  );
};
