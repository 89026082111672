// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {MeasuringStrategy} from '@dnd-kit/core';
import {verticalListSortingStrategy} from '@dnd-kit/sortable';
import {addCN} from '@/_core/util/cssHelper';
import {ProfileFeedbackItem} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {ProfileSoftfactionaryItem} from '@modules/ContentEngine/components/items/profile/common/ProfileSoftfactionaryItem';
import {DnDContext} from '@modules/Core/components/base/dnd/DnDContext';
import {useDnDLists} from '@modules/Core/components/base/dnd/dndLists';
import {Droppable} from '@modules/Core/components/base/dnd/Droppable';
import {SortableContext} from '@modules/Core/components/base/dnd/SortableContext';
import {Typography} from '@modules/Core/components/base/Typography';
import {Group} from '@modules/Core/components/layout/Group';
import {Section} from '@modules/Core/components/layout/Section';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {useProfileValuesState} from '@modules/Profile/hooks/profileValuesState';
import {TeamValuesActions} from '@modules/Team/components/team_analysis/values/TeamValuesActions';
import {TeamValuesChangesDialog} from '@modules/Team/components/team_analysis/values/TeamValuesChangesDialog';
import {TeamValuesDnDCard} from '@modules/Team/components/team_analysis/values/TeamValuesDnDCard';
import {useTeamValues} from '@modules/Team/hooks/Analysis/Values/teamValues';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {_TeamValues} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profileId: string;
  teamId: string;
  result: _TeamValues;
  allProfiles: Record<string, _TeamProfile>;
  transparencyAccepted: boolean;
}

const WAIT_BEFORE_SHOWING_CHANGE_NOTIFICATION = 1;

const SORTABLE_ID = 'team-values';
export const TeamValuesItem: React.FC<_Props> = ({
  transparencyAccepted = false,
  profileId,
  teamId,
  allProfiles,
  result,
}) => {
  const dialog = useDialog();
  const snackbar = useSnackbar();
  const {
    teamValues,
    setTeamValues,
    isReady,
    onDelete,
    onHighlight,
    notifiedProfiles,
    updateNotifiedProfiles,
    updateTeamValues,
  } = useTeamValues(teamId, result);
  const {mode, switchMode} = useProfileValuesState();
  const teamValuesDnDList = useDnDLists(SORTABLE_ID, teamValues, setTeamValues);
  // const [onSave] = useProfileValuesApiActions(allValues, profileValues, originalProfileValues, switchMode, profileId);
  const isDisabled = mode === 'view';
  const [changedDialogOpen, setChangedDialogOpen] = useState<boolean>(false);
  const shownNotificationRef = useRef<boolean>(false);

  useEffect(() => {
    if (!notifiedProfiles) {
      return;
    }

    if (!notifiedProfiles || notifiedProfiles.length === 0) {
      showChangeNotificationIfExists();
    }
  }, [notifiedProfiles, result?.diff, isReady]);
  const showChangesNotificationAlert = useCallback(() => {
    if (shownNotificationRef.current) {
      return;
    }
    shownNotificationRef.current = true;

    dialog.show({
      title: trans('personal_values.new_changes.title'),
      children: trans('personal_values.new_changes.description'),
      confirmLabel: trans('base.yes'),
      cancelLabel: trans('base.later'),
      onConfirm: () => {
        void updateNotifiedProfiles(profileId);
        shownNotificationRef.current = true;
        setChangedDialogOpen(true);
      },
      onCancel: () => {
        void updateNotifiedProfiles(profileId);
        snackbar.info(trans('personal_values.new_changes_ignored.description'));
      },
    });
  }, [shownNotificationRef]);

  const showChangeNotificationIfExists = () => {
    if (
      !isReady ||
      Object.keys(result?.diff || {})?.length === 0 ||
      shownNotificationRef.current ||
      notifiedProfiles.includes(profileId)
    ) {
    }

    // If profileId have not been notified yet, notify them
    // if (!notifiedProfiles || notifiedProfiles.length === 0) {
    //   setTimeout(() => {
    //     showChangesNotificationAlert();
    //   }, WAIT_BEFORE_SHOWING_CHANGE_NOTIFICATION * 1000);
    // }
  };

  function onAdoptChanges() {
    shownNotificationRef.current = true;
    setChangedDialogOpen(true);
  }

  function onSave() {
    void updateTeamValues(() => {
      mode === 'edit' && switchMode('view');
    });
  }

  logger.debug('TeamValuesItem', {teamValues, result, transparencyAccepted, allProfiles});
  return (
    <>
      {Boolean(mode === 'view') && (
        <Section className="mb-8">
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: trans('profile_modules.services.profile_values.description'),
            }}
          />
        </Section>
      )}
      {/* {!isSharedView && ( */}
      {/*  <NewProfileValuesDialog */}
      {/*    onItemCreated={onNewItemCreated} */}
      {/*    open={showNewDialog} */}
      {/*    onClose={() => setShowNewDialog(false)} */}
      {/*  /> */}
      {/* )} */}
      <TeamValuesChangesDialog
        open={changedDialogOpen}
        setOpen={setChangedDialogOpen}
        teamId={teamId}
        result={result}
        transparencyAccepted={transparencyAccepted}
        allProfiles={allProfiles}
      />
      <Group>
        <Section>
          <DnDContext
            options={{
              activationConstraint: {
                distance: 10,
              },
            }}
            measuring={{droppable: {strategy: MeasuringStrategy.WhileDragging}}}
            // onDragEnd={onDragEnd}
            lists={[teamValuesDnDList]}
            renderOverlay={(activeItemId: string) => {
              if (activeItemId) {
                const item = teamValues.find(item => item.id === activeItemId);
                if (item) {
                  return <TeamValuesDnDCard key={item.id} value={item} />;
                }
              }

              // Returning null signals the default overlay should be rendered
              return null;
            }}
          >
            <div className={addCN('flex flex-row gap-3')}>
              <div className="relative-col-width-5 flex flex-col">
                <Section
                  title={trans('personal_values.team_values_selection.title')}
                  description={trans('personal_values.team_values_selection.description')}
                  fullWidth
                  titleVariant="h6"
                  descriptionVariant="body1"
                />
              </div>
              <div className="relative-col-width-5 items-center">
                <SortableContext
                  disabled={isDisabled}
                  items={teamValues}
                  id={SORTABLE_ID}
                  strategy={verticalListSortingStrategy}
                >
                  <Droppable disabled={isDisabled} id={SORTABLE_ID}>
                    <Section
                      fullWidth
                      className="min-h-[300px]"
                      title={trans('personal_values.team_values_list_title')}
                      titleVariant="h6"
                    >
                      {teamValues?.map(value => (
                        <TeamValuesDnDCard
                          onDelete={() => onDelete(value.id)}
                          onSelect={() => onHighlight(value.id)}
                          disabled={isDisabled}
                          key={value.id}
                          value={value}
                        />
                      ))}
                    </Section>
                  </Droppable>
                </SortableContext>
              </div>
            </div>
            <TeamValuesActions
              onSave={onSave}
              hasChanges={Object.keys(result?.diff || {}).length > 0}
              onAdoptChanges={onAdoptChanges}
              switchMode={switchMode}
              mode={mode}
            />
          </DnDContext>
        </Section>
      </Group>
    </>
  );
};
