// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, useEffect, useState} from 'react';
import {generate} from '@modules/Core/components/base/helper';
import {ServiceInformationCard} from '@modules/Core/components/platform/services/ServiceInformationCard';
import {SERVICE_TEAM_REFLECTION} from '@modules/Core/config/services';
import {useLoader} from '@modules/Core/hooks/state/loader';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {productTitlesLangKeys} from '@modules/Profile/config/servicesLangProps';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {
  getServicePreconditions,
  getUnfulfilledServicePreconditions,
  isServiceAvailable,
  isServiceConsented,
} from '@modules/Profile/util/serviceUtil';
import {getTeamServiceImage} from '@modules/Team/util/serviceUtil';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamServiceInformationProps {
  service: string;
  labelNextButton?: string;
  onNextClicked?: () => void;
  isCoach?: boolean;
  teamId?: string;
}

export const TeamServiceInformation: React.FC<_TeamServiceInformationProps> = ({
  service,
  labelNextButton = null,
  onNextClicked = null,
  isCoach = false,
  teamId,
}) => {
  const [currentProfile] = useProfile();
  const {currentLanguage} = useLanguage();
  const navigateToFlow = useNavigateToFlow();
  // Service Data
  const [title, setTitle] = useState<string | null>('');
  const [content, setContent] = useState<string | ReactNode | null>('');
  const [time, setTime] = useState<string | null>('');
  const [image, setImage] = useState<string | null>('');
  const [actions, setActions] = useState<_UIAction[]>([]);

  const [showConsentDialog, setShowConsentDialog] = useState<boolean>(false);

  /**
   * Set service title, content, image & actions
   */
  useEffect(() => {
    if (!service) {
      return;
    }
    const newActions: _UIAction[] = [];
    const servicePreconditions = getServicePreconditions(service, teamId);
    const unfulfilledServicePreconditions = getUnfulfilledServicePreconditions(service, teamId);
    const legacyConsent = currentProfile?.legacy_consent?.includes('consent');

    setTitle(trans(`products.${service}.title`));
    setContent(trans(`products.${service}.content`));
    setImage(getTeamServiceImage(service));
    setTime(trans(`products.${service}.time`, null, false));

    if (
      service === SERVICE_TEAM_REFLECTION &&
      isServiceAvailable(service, teamId) &&
      unfulfilledServicePreconditions.length === 0
    ) {
      // setContent(trans(`products.${service}.content_ready`));
    }
    // Ideally, this should come from a backend json config, for now, we do this

    if (!isServiceAvailable(service)) {
      newActions.push({
        type: 'upgrade_button',
        source: 'institution',
      });
    } else if (
      service === SERVICE_TEAM_REFLECTION &&
      isServiceAvailable(service, teamId) &&
      unfulfilledServicePreconditions.length > 0
    ) {
      setContent(
        <div
          dangerouslySetInnerHTML={{
            __html: trans('service.preconditions_unfulfilled.team_reflection', {
              done: (servicePreconditions?.length ?? 0) - (unfulfilledServicePreconditions?.length ?? 0),
            }),
          }}
        />
      );
      unfulfilledServicePreconditions.forEach((precondition, index) => {
        newActions.push({
          label: trans(productTitlesLangKeys[precondition]),
          type: 'link_button',
          onClick: () => navigateToFlow(getSurveyFlow(precondition).name),
        });
      });

      setImage(getTeamServiceImage(`${SERVICE_TEAM_REFLECTION}_unfulfilled`));
    } else if (!isServiceConsented(service, teamId)) {
      newActions.push({
        label: labelNextButton ?? trans('base.next'),
        type: 'button',
        onClick: legacyConsent ? handleConsent : () => setShowConsentDialog(true),
      });
    } else {
      newActions.push({
        label: labelNextButton ?? trans('base.next'),
        type: 'button',
        onClick: () => onNextClicked?.(),
      });
    }

    // add uuids to actions
    newActions.forEach(action => {
      action.uuid = generate();
    });

    setActions(newActions);
  }, [service, currentProfile, currentLanguage]);

  async function handleConsent(): Promise<void> {
    try {
      const method = 'service.consent';
      const params = {
        profileId: currentProfile?.id,
        teamId,
        service,
      };

      const response = await performFlowAction({method, params});

      if (response?.data?.status === 'success') {
        setShowConsentDialog?.(false);
        dispatchReactEvent('navigate.start');
        onNextClicked?.();
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
  }

  // Actions as column ONLY if TA & precondition unfulfilled
  const isTAUnfulfilled =
    service === SERVICE_TEAM_REFLECTION && getUnfulfilledServicePreconditions(service, teamId).length > 0;

  return (
    <ServiceInformationCard
      showConsentDialog={showConsentDialog}
      setShowConsentDialog={setShowConsentDialog}
      isCoach={isCoach}
      onNextClicked={onNextClicked}
      service={service}
      title={title}
      content={content}
      time={time}
      image={image}
      actions={actions}
      teamId={teamId}
      alignActions="start"
      contentAsHTML={!isTAUnfulfilled}
    />
  );
};
