// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';

interface UseOnMouseEnterProps {
  ref: React.RefObject<HTMLElement>;
  distanceIn: number;
  distanceOut: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const useOnMouseEnter = ({ref, distanceIn, distanceOut, onMouseEnter, onMouseLeave}: UseOnMouseEnterProps) => {
  const [isInside, setIsInside] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (!ref.current) return;

      const rect = ref.current.getBoundingClientRect();
      const mouseX = event.clientX;
      const mouseY = event.clientY;

      // Calculate the closest point on the element's border to the cursor
      const closestX = Math.max(rect.left, Math.min(mouseX, rect.right));
      const closestY = Math.max(rect.top, Math.min(mouseY, rect.bottom));

      // Calculate the distance from the cursor to the closest point on the element
      const dx = mouseX - closestX;
      const dy = mouseY - closestY;
      const distanceToElement = Math.sqrt(dx * dx + dy * dy);

      if (distanceToElement <= distanceIn && !isInside) {
        setIsInside(true);
        onMouseEnter();
      } else if (distanceToElement > distanceOut && isInside) {
        setIsInside(false);
        onMouseLeave();
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [ref, distanceIn, distanceOut, isInside, onMouseEnter, onMouseLeave]);
};

export default useOnMouseEnter;
