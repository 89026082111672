// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute} from '@app/_core/util/util';
import {
  IconAttentionSeeker,
  IconBriefcase,
  IconCommunication,
  IconCoordinator,
  IconCritic,
  IconDifficult,
  IconEvaluator,
  IconHeart,
  IconHeartFiled,
  IconLearning,
  IconListener,
  IconMindfulness,
  IconMotivation,
  IconMotives,
  IconPassive,
  IconPowerSeaker,
  IconProactivity,
  IconProblemSolver,
  IconResponsibility,
  IconRoles,
  IconSelfEfficacy,
  IconSmile,
  IconSocialRole,
  IconTaskCompleter,
  IconTaskMotivator,
  IconTeamLeader,
  IconTeamworkSupporter,
  IconThrust,
} from '@modules/Core/components/base/Icons/Icons';
import {
  SERVICE_INITIAL_SOFTFACTS_ANALYSIS,
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_POTENTIALS,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_TRUST,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_TEAM_DEVELOPMENT,
  SOFTFACT_SERVICES,
  THIRD_PERSON_FEEDBACK,
} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {pascalCase} from '@modules/Core/util/strings';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {surveyFlows} from '@modules/FlowEngine/config/surveyFlows';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {_Profile, _ShortProfile} from '@modules/Profile/types/profile.model';
import {_ProfileMotivesSubGroup} from '@modules/Profile/types/service.model';
import {appState} from '@modules/State/util/util';
import {_TeamProfile} from '@modules/Team/types/team.model';
import {getTeamServiceImage, isTeamDevModule} from '@modules/Team/util/serviceUtil';

export function isSoftfact(service: string): boolean {
  return SOFTFACT_SERVICES.includes(service);
}

export const isOldSoftfact = (service: string): boolean =>
  [
    SERVICE_PROFILE_BASE_AND_ANALYSIS,
    SERVICE_PROFILE_ROLES,
    SERVICE_PROFILE_VALUES,
    SERVICE_PROFILE_WAY_OF_WORKING,
    SERVICE_PROFILE_MOTIVE_STRUCTURE,
  ].includes(service);

export function isSoftfactOrderCalculated(): boolean {
  const profile = appState().profile?.currentProfile;
  return Boolean(profile?.softfactsOrderCalculated);
}

export const getProfileLatestSoftfact = (): string | null => {
  const profile = appState().profile?.currentProfile;
  return profile?.latestSoftfact;
};

export function getProfilePic(
  currentProfile?: _Profile | _ShortProfile | _TeamProfile | null,
  useDefault = false
): string {
  if (!!currentProfile?.profilePicture?.length && !useDefault) {
    return currentProfile.profilePicture;
  }
  return `/assets/img/svg/User-avatar.svg`;
}

export function getSurveyFlow(service: string): {name: string; isTeam?: boolean} {
  return surveyFlows[service];
}

export function surveyFlowRoute(service: string): string | null {
  const flow = getSurveyFlow(service);
  const flowName = pascalCase(flow.name);
  return flowRoute(flowName);
}

export function getActiveSoftfact(path: string): string | null {
  const pattern = new RegExp(
    `^/flow/(${Object.values(surveyFlows)
      .map(flow => flow.name)
      .join('|')})`
  );

  const match = path.match(pattern);
  if (match) {
    const service = Object.entries(surveyFlows).find(([, flow]) => flow.name === match[1])?.[0];
    if (service) {
      return SOFTFACT_SERVICES.includes(service) ? service : null;
    }
  }
  return null;
}

/*
 SERVICE_PROFILE_BASE_AND_ANALYSIS,
    SERVICE_PROFILE_WAY_OF_WORKING,
    SERVICE_PROFILE_VALUES,
    SERVICE_PROFILE_TRUST,
    ,
    SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
 */
export function getServiceIcon(service: string): React.ElementType | null {
  const iconsMap: Record<string, React.ElementType> = {
    [SERVICE_PROFILE_RESILIENCE]: IconDifficult,
    [SERVICE_PROFILE_VALUES]: IconHeart,
    [SERVICE_PROFILE_RESPONSIBILITY]: IconResponsibility,
    [SERVICE_PROFILE_LEARNING]: IconLearning,
    [SERVICE_PROFILE_SELF_EFFICACY]: IconSelfEfficacy,
    [SERVICE_PROFILE_COMMUNICATION]: IconCommunication,
    [SERVICE_PROFILE_MOTIVATION]: IconMotivation,
    [SERVICE_PROFILE_MOTIVE_STRUCTURE]: IconMotives,
    [SERVICE_PROFILE_MINDFULNESS]: IconMindfulness,
    [SERVICE_PROFILE_PROACTIVITY]: IconProactivity,
    [SERVICE_PROFILE_ROLES]: IconRoles,
    [SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE]: IconHeartFiled,
    [SERVICE_PROFILE_TRUST]: IconThrust,
    [SERVICE_PROFILE_WAY_OF_WORKING]: IconBriefcase,
    [SERVICE_PROFILE_BASE_AND_ANALYSIS]: IconSmile,
  };

  if (iconsMap[service]) return iconsMap[service];

  console.log('no icon for service: ', service);
  return null;
}

export function getLockedImageIllustration(): string {
  return getIllustrationV2Image('locked-exercises');
}

export function getIllustrationV2Image(name: string): string {
  // if ends with svg, remove
  if (name.endsWith('.svg')) {
    name = name.slice(0, -4);
  }

  return `/assets/img/illustrations_v2/${name}.svg`;
}

export function getServiceImage(service: string): string | null {
  const imagesMap: Record<string, string> = {
    [SERVICE_PROFILE_BASE_AND_ANALYSIS]: 'high five',
    [SERVICE_PROFILE_ROLES]: 'roles image',
    [SERVICE_PROFILE_VALUES]: 'your values',
    [SERVICE_PROFILE_WAY_OF_WORKING]: 'ways of working',
    [SERVICE_PROFILE_MOTIVE_STRUCTURE]: 'motives',

    [SERVICE_PROFILE_COMMUNICATION]: 'team session',
    [SERVICE_PROFILE_MOTIVATION]: 'high five',
    [SERVICE_PROFILE_POTENTIALS]: 'high five',
    [SERVICE_PROFILE_TRUST]: 'trust',
    [SERVICE_PROFILE_RESPONSIBILITY]: 'girl on a laptop',
    [SERVICE_PROFILE_PROACTIVITY]: 'man reading book',
    [SERVICE_PROFILE_SELF_EFFICACY]: 'power',

    [SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE]: 'emotions',
    [SERVICE_PROFILE_LEARNING]: 'learning',
    [SERVICE_PROFILE_MINDFULNESS]: 'team image',
    [SERVICE_PROFILE_RESILIENCE]: 'woman',
    [SERVICE_INITIAL_SOFTFACTS_ANALYSIS]: 'soft fact analysis',
    [SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE]: 'performance_satisfaction',
    [THIRD_PERSON_FEEDBACK]: '360 feedback',
  };

  if (!imagesMap[service]) {
    // team dev
    if (isTeamDevModule(service)) {
      return getTeamServiceImage(SERVICE_TEAM_DEVELOPMENT);
    }
    return null;
  }
  return `/assets/img/design_images/${imagesMap[service]}.png`;
}

export function currentProfile(): _Profile | null {
  return appState().profile?.currentProfile;
}

export function isRequestSent(requestName: string): boolean {
  const currentProfile = appState().profile?.currentProfile;
  return (currentProfile?.sentRequests ?? [])?.includes(requestName);
}

export function getProfileRoleIcon(role: string): React.ElementType | null {
  const iconsMap: Record<string, React.ElementType> = {
    critic: IconCritic,
    social: IconSocialRole,
    listener: IconListener,
    evaluator: IconEvaluator,
    coordinator: IconCoordinator,
    problem_solver: IconProblemSolver,
    team_leader: IconTeamLeader,
    task_completer: IconTaskCompleter,
    task_motivator: IconTaskMotivator,
    teamwork_support: IconTeamworkSupporter,
    passive: IconPassive,
    attention_seeker: IconAttentionSeeker,
    power_seeker: IconPowerSeaker,
  };

  if (iconsMap[role]) return iconsMap[role];

  console.log('no icon for role: ', role);
  return null;
}

export function isInitialProfileSurvey(service: string): boolean {
  return service === SERVICE_INITIAL_SOFTFACTS_ANALYSIS;
}

export function shareResult(service: string): void {
  fireDialogFlow(
    FLOWS.ShareResult,
    null,
    {
      hideButtons: true,
      showCloseButton: true,
    },
    {
      services: [service],
    }
  );
}

export function getMotiveGroupImage(motiveGroupTitle: _ProfileMotivesSubGroup): string | null {
  const imagesMap: Record<_ProfileMotivesSubGroup, string> = {
    development: 'man reading book',
    selfrealization: 'motives self realization',
    career: 'motives career',
    selfoptimization: 'motives self optimization',
    independance: 'girl on a laptop',
    power: 'power',
    fun: 'motives fun',
    achievement: 'motives achievement',
    responsibility: 'man on a scooter',
    goalinternalization: 'man hits target',
    fairness: 'motives fairness',
    idealism: 'motives idealism',
    belonging: 'team celebration',
    acceptance: 'motives acceptance',
    security: 'motives security',
    status: 'motives status',
  };

  if (!motiveGroupTitle) return null;

  return `/assets/img/design_images/${imagesMap[motiveGroupTitle]}.png`;
}

export const profileExistsSince = (
  dateFilterType: 'week' | 'last_week' | 'month' | 'last_month' | 'year' | 'last_year' | 'all_time'
): boolean => {
  const profile = currentProfile();
  const creationDate = profile?.created_at;

  if (!creationDate) return false;

  const today = new Date();
  const profileCreationDate = new Date(creationDate);

  switch (dateFilterType) {
    case 'week': {
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay()); // Start of current week
      return profileCreationDate <= startOfWeek;
    }

    case 'last_week': {
      const startOfLastWeek = new Date(today);
      startOfLastWeek.setDate(today.getDate() - today.getDay() - 7); // Start of last week
      return profileCreationDate <= startOfLastWeek;
    }

    case 'month': {
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      return profileCreationDate <= startOfMonth;
    }

    case 'last_month': {
      const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      return profileCreationDate <= startOfLastMonth;
    }

    case 'year': {
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      return profileCreationDate <= startOfYear;
    }

    case 'last_year': {
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
      return profileCreationDate <= startOfLastYear;
    }

    case 'all_time':
      return true; // Profile always exists for all time view

    default:
      return false;
  }
};
