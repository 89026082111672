// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';

export const createProjectAction = async (name: string): Promise<{id: string; status: string} | null> => {
  const method = 'third-person-feedback.new';
  const params = {
    name,
  };

  try {
    const response = await performFlowAction({
      method,
      params,
    });

    const responseData = response?.data;

    if (responseData?.status === 'OK' && responseData?.id) {
      return {
        id: responseData.id,
        status: responseData.status,
      };
    }
    logger.error('Create Project Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Create Project Action encountered an error:', e);
    return null;
  }
};

export const inviteMembersAction = async (
  feedbackId: string | null,
  emails: string[]
): Promise<{links: string[]; status: string} | null> => {
  try {
    const response = await performFlowAction({
      method: 'third-person-feedback.invite',
      params: {
        id: feedbackId,
        emails,
      },
    });

    const responseData = response?.data;

    if (responseData?.status === 'success' && responseData?.data) {
      return {
        links: responseData.data.links,
        status: responseData.data.status,
      };
    }
    logger.error('Invite Members Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Invite Members Action encountered an error:', e);
    return null;
  }
};
