// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconAdd} from '@modules/Core/components/base/Icons/Icons';
import {Section} from '@modules/Core/components/layout/Section';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {}

export const CandidateAnalysisOverviewPlaceholder: React.FC<_Props> = ({}) => {
  const navigateToFlow = useNavigateToFlow();
  return (
    <Section
      imageViewDirection="vertical"
      title={trans('candidate_analysis.new_placeholder.title')}
      description={trans('candidate_analysis.new_placeholder.description')}
      image="/assets/img/design_images/new_candidate_analysis.png"
    >
      <div className="flex justify-center items-center">
        <Button
          width="relative-col-width-3"
          icon={IconAdd}
          label={trans('candidate_analysis.new_entry')}
          onClick={() => navigateToFlow('CandidateAnalysis', {new: true})}
        />
      </div>
    </Section>
  );
};
