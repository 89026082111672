// @ts-nocheck
/* eslint-disable */

import React, {createContext, useEffect, useRef, useState} from 'react';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {uuid} from '@modules/Core/util/util';
import {getWindowLocationData} from '@modules/Navigation/util/navigationUtil';
import {_TrackingConfig, activityTimeoutInSeconds} from '@modules/Tracking/config/trackingsConfig';
import useUserActivity from '@modules/Tracking/hooks/userActivity';
import {submitProfileTrackingEventAction} from '@modules/Tracking/util/trackingUtil';

export interface _RunningTrackingConfig extends _TrackingConfig {
  startTime: string;
  intervalId?: number;
}

export interface _TrackingData {
  type: 'periodical' | 'event';
  intervalId?: number;
  eventName?: string;
}

export interface _TrackingContextProps {
  periodicalTrack: (config: _TrackingConfig) => string;
  eventTrack: (config: _TrackingConfig) => string;
  stopTracking: (trackingId: string) => void;
  runningTrackings: Record<string, _TrackingConfig>;
}
interface TabMessage {
  type: 'heartbeat' | 'primary-claim' | 'event-tracked';
  tabId: string;
  timestamp: number;
  trackingId?: string;
  eventData?: any;
}

const TRACKING_CHANNEL = 'tracking-coordination';
const TAB_HEARTBEAT_INTERVAL = 5000; // 5 seconds

export const TrackingContext = createContext<_TrackingContextProps | undefined>(undefined);

export const TrackingProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const isIdle = useUserActivity(activityTimeoutInSeconds);
  const runningTrackings = useRef<Record<string, _RunningTrackingConfig>>({});
  const isIdleRef = useRef<boolean>(false);

  const tabId = useRef<string>(uuid());
  const isPrimaryTab = useRef<boolean>(false);
  const channelRef = useRef<BroadcastChannel | null>(null);
  const lastHeartbeats = useRef<Record<string, number>>({});
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!window.BroadcastChannel) {
      isPrimaryTab.current = true;
      setIsInitialized(true);
      return;
    }

    channelRef.current = new BroadcastChannel(TRACKING_CHANNEL);
    channelRef.current.onmessage = handleChannelMessage;

    claimPrimaryStatus();
    const heartbeatInterval = setInterval(sendHeartbeat, TAB_HEARTBEAT_INTERVAL);

    return () => {
      clearInterval(heartbeatInterval);
      channelRef.current?.close();
    };
  }, []);

  const sendHeartbeat = () => {
    if (!channelRef.current) return;

    const message: TabMessage = {
      type: 'heartbeat',
      tabId: tabId.current,
      timestamp: Date.now(),
    };

    channelRef.current.postMessage(message);
    checkAndClaimPrimaryStatus();
  };

  const checkAndClaimPrimaryStatus = () => {
    const now = Date.now();
    Object.entries(lastHeartbeats.current).forEach(([id, timestamp]) => {
      if (now - timestamp > TAB_HEARTBEAT_INTERVAL * 2) {
        delete lastHeartbeats.current[id];
      }
    });

    if (Object.keys(lastHeartbeats.current).length === 0 && !isPrimaryTab.current) {
      claimPrimaryStatus();
    }
  };

  const claimPrimaryStatus = () => {
    if (!channelRef.current) return;

    channelRef.current.postMessage({
      type: 'primary-claim',
      tabId: tabId.current,
      timestamp: Date.now(),
    });

    isPrimaryTab.current = true;
    setIsInitialized(true);
  };

  const handleChannelMessage = (event: MessageEvent<TabMessage>) => {
    const {type, tabId: messageTabId, timestamp} = event.data;

    switch (type) {
      case 'heartbeat':
        lastHeartbeats.current[messageTabId] = timestamp;
        break;
      case 'primary-claim':
        if (messageTabId !== tabId.current) {
          isPrimaryTab.current = false;
          setIsInitialized(true);
        }
        break;
      case 'event-tracked':
        if (messageTabId !== tabId.current && event.data.trackingId) {
          stopTracking(event.data.trackingId);
        }
        break;
    }
  };
  const addTracking = (trackingId: string, trackingData: _TrackingConfig | _RunningTrackingConfig): void => {
    if (!trackingId) {
      logger.warn('[TrackingProvider] Tracking ID is required');
      return;
    }

    if (runningTrackings.current?.[trackingId]) {
      logger.warn(`[TrackingProvider] Tracking with ID ${trackingId} already exists`);
      return;
    }

    const enrichedTrackingData = {...trackingData, startTime: new Date().toISOString()};

    runningTrackings.current = {...runningTrackings.current, [trackingId]: enrichedTrackingData};
  };

  const submitTrackingEvent = async (trackingId: string): Promise<void> => {
    if (!isPrimaryTab.current) return;
    const tracking = runningTrackings.current?.[trackingId];
    if (!tracking) {
      logger.error(`[TrackingProvider] Tracking with ID ${trackingId} not found`);
      return;
    }
    const endTime = new Date().toISOString();
    const locationData = getWindowLocationData();

    let metadata = {};
    if (tracking.type === 'periodical') {
      metadata = tracking.metadata?.({startTime: tracking.startTime, endTime, ...locationData});
    } else {
      const data = {time: endTime, ...locationData};
      metadata = tracking.metadata?.(data);
    }
    channelRef.current?.postMessage({
      type: 'event-tracked',
      tabId: tabId.current,
      trackingId,
      timestamp: Date.now(),
      eventData: metadata,
    });
    await submitProfileTrackingEventAction({
      category: tracking.category,
      source: location.pathname,
      type: tracking.eventType,
      action: tracking.action,
      metadata,
      withSave: true,
      withEvent: true,
    });
    if (tracking.type === 'periodical') {
      tracking.startTime = endTime;
    }
  };

  const createId = (eventName: string): string => {
    return `${uuid()}-${eventName}`;
  };

  const eventTrack = (config: _TrackingConfig): string => {
    logger.info('[TrackingProvider] Event tracked manually');
    const callback = (trackId: string) => {
      logger.info(`[TrackingProvider] Event ${config.eventName} triggered`);

      void submitTrackingEvent(trackId);
    };
    const trackingId = createId(config.eventName!);

    const enrichedConfig = {...config, eventCallback: callback} as _RunningTrackingConfig;

    addTracking(trackingId, enrichedConfig);

    addReactEventListener(config.eventName!, () => callback(trackingId));

    if (config.fireOnStart) {
      void submitTrackingEvent(trackingId);
    }

    return trackingId;
  };

  const periodicalTrack = (config: _TrackingConfig): string => {
    const {period: periodInSeconds} = config;

    logger.info('[TrackingProvider] Starting periodical tracking');
    if (Number.isNaN(periodInSeconds) || periodInSeconds <= 0) {
      logger.error('[TrackingProvider] Invalid period for periodical tracking');
      return '';
    }
    const trackingId = createId('periodical');
    const interval = setInterval(() => {
      logger.info('[TrackingProvider] Periodical tracking with period (seconds)', periodInSeconds);
      if (isIdleRef.current) {
        logger.info('[TrackingProvider] User is idle, not tracking periodical event');
        return;
      }
      void submitTrackingEvent(trackingId);
    }, periodInSeconds * 1000);

    const enrichedConfig = {...config, intervalId: interval} as _RunningTrackingConfig;
    addTracking(trackingId, enrichedConfig);

    if (config.fireOnStart) {
      void submitTrackingEvent(trackingId);
    }
    return trackingId;
  };

  const stopTracking = (trackingId: string): void => {
    if (!runningTrackings.current[trackingId]) {
      logger.warn(
        `[TrackingProvider] Tracking with ID ${trackingId} does not exist, currently running: ${JSON.stringify(runningTrackings)}`
      );
      return;
    }
    const trackingData = runningTrackings.current[trackingId];

    if (trackingData?.type === 'periodical') {
      if (!trackingData.intervalId) {
        logger.error(`[TrackingProvider] Interval ID not found for periodical tracking with ID ${trackingId}`);
        return;
      }
      clearInterval(trackingData.intervalId);
    } else if (trackingData?.type === 'event') {
      const {eventName} = trackingData;
      if (!eventName) {
        logger.error(`[TrackingProvider] Event name not found for event tracking with ID ${trackingId}`);
        return;
      }
      removeReactEventListener(eventName, trackingData.eventCallback);
    }

    runningTrackings.current = Object.keys(runningTrackings.current).reduce((acc, key) => {
      if (key !== trackingId) {
        acc[key] = runningTrackings.current[key];
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    if (isIdle) {
      logger.info('[TrackingProvider] User is idle');
      // TODO: Loop on periodical trackings, send event now

      Object.keys(runningTrackings.current ?? {}).forEach(trackingId => {
        const tracking = runningTrackings.current[trackingId];
        if (tracking.type === 'periodical') {
          logger.info(`[TrackingProvider] User is idle, sending periodical tracking event for ${trackingId}`);
          void submitTrackingEvent(trackingId);
        }
      });
    } else {
      logger.info('[TrackingProvider] User is active');
      // TODO: Loop on periodical trackings, and set start time to now

      Object.keys(runningTrackings.current ?? {}).forEach(trackingId => {
        const tracking = runningTrackings.current[trackingId];
        if (tracking.type === 'periodical') {
          logger.info(`[TrackingProvider] User is active, updating start time for periodical tracking ${trackingId}`);
          tracking.startTime = new Date().toISOString();
        }
      });
    }
    isIdleRef.current = isIdle;
  }, [isIdle]);

  if (!isInitialized) {
    return null;
  }
  return (
    <TrackingContext.Provider
      value={{periodicalTrack, eventTrack, stopTracking, runningTrackings: runningTrackings.current}}
    >
      {children}
    </TrackingContext.Provider>
  );
};
