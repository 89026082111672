// @ts-nocheck
/* eslint-disable */

import {logger} from '@sentry/utils';
import {
  _CandidateAnalysis,
  _CandidateAnalysisSubmission,
} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {ACTION_STATUS_SUCCESS} from '@modules/Core/config/constants';
import {performFlowAction} from '@modules/FlowEngine/services/api';

// Function to publish a candidate analysis
export const publishCandidateAnalysisAction = async (analysisId: string): Promise<boolean> => {
  const method = 'candidate_analysis.publish';
  const params = {analysisId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Publish Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Publish Candidate Analysis Action encountered an error:', e);
    return false;
  }
};

// Function to close a candidate analysis
export const closeCandidateAnalysisAction = async (analysisId: string): Promise<boolean> => {
  const method = 'candidate_analysis.close';
  const params = {analysisId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Close Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Close Candidate Analysis Action encountered an error:', e);
    return false;
  }
};

// Function to delete a candidate analysis
export const deleteCandidateAnalysisAction = async (analysisId: string): Promise<boolean> => {
  const method = 'candidate_analysis.delete';
  const params = {analysisId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Delete Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Delete Candidate Analysis Action encountered an error:', e);
    return false;
  }
};

// Function to share a candidate analysis
export const shareCandidateAnalysisAction = async (analysisId: string, shareWith: string[]): Promise<boolean> => {
  const method = 'candidate_analysis.share';
  const params = {analysisId, shareWith};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return true;
    }
    logger.error('Share Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Share Candidate Analysis Action encountered an error:', e);
    return false;
  }
};

// Function to save a candidate analysis
export const saveCandidateAnalysisAction = async (
  body: _CandidateAnalysis,
  analysisId?: string
): Promise<string | null> => {
  const method = 'candidate_analysis.save';
  const params = {
    analysisId,
    ...(body ?? {}),
  };

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Save Candidate Analysis Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      return responseData.data.analysis_id;
    }
    logger.error('Save Candidate Analysis Action failed:', responseData?.error);
    return null;
  } catch (e) {
    logger.error('Save Candidate Analysis Action encountered an error:', e);
    return null;
  }
};

// Function to finalize a candidate analysis submission
export const finalizeCandidateAnalysisSubmission = async (
  analysisId: string,
  submission: _CandidateAnalysisSubmission
): Promise<boolean> => {
  try {
    const response = await performFlowAction(
      {
        method: 'candidate_analysis.finalize',
        params: {
          analysisId,
          submission,
        },
      },
      {
        refetchProfile: true,
        unauthorized: true,
      }
    );

    const responseData = response?.data;

    logger.debug('Finalize Candidate Analysis Action response:', responseData);
    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data) {
      logger.debug('Candidate Analysis submission finalized successfully:', responseData.data);
      return true;
    }
    logger.error('Finalize Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Finalize Candidate Analysis Action encountered an error:', e);
    return false;
  }
};

export const duplicateCandidateAnalysisAction = async (analysisId: string): Promise<string | boolean> => {
  const method = 'candidate_analysis.duplicate';
  const params = {analysisId};

  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {
        refetchProfile: true,
      }
    );

    const responseData = response?.data;

    if (responseData?.status === ACTION_STATUS_SUCCESS && responseData?.data?.analysis_id) {
      return responseData?.data?.analysis_id;
    }
    logger.error('Duplicate Candidate Analysis Action failed:', responseData?.error);
    return false;
  } catch (e) {
    logger.error('Duplicate Candidate Analysis Action encountered an error:', e);
    return false;
  }
};
