// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {refreshReduxState} from '@modules/State/util/util';
import {_ShortTeam} from '@modules/Team/types/team.model';
import {trans} from '@modules/Translations/util/i18n';
import {showEmailDialog} from './emailDialogHelper';

interface SendReminderParams {
  teamId?: string;
  service: string;
  profileIds: string[];
  setDialogConfig?: (config: _Dialog & {children?: React.ReactElement}) => void;
  onCloseDialog?: () => void;
  snackbar: {
    success: (message: string) => void;
    danger: (message: string) => void;
  };
  availableEmails?: string[];
}

export async function sendReminder({
  teamId,
  service,
  profileIds,
  setDialogConfig,
  onCloseDialog,
  snackbar,
  availableEmails,
}: SendReminderParams) {
  try {
    const response = await performFlowAction({
      method: teamId ? 'team.reminder' : 'institution.reminder',
      params: {
        teamId,
        institutionId: currentInstitution()?.id,
        profileIds,
        service,
      },
    });

    if (response.data?.status === 'success') {
      if (response?.data?.data && setDialogConfig) {
        const emailContent = response.data.data;
        logger.debug('sendReminder response:', emailContent);
        showEmailDialog({
          emailContent,
          setDialogConfig,
          onCloseDialog,
          availableEmails,
        });
        return;
      }
      snackbar.success(trans(response?.data?.message));
      await refreshReduxState();
    } else {
      snackbar.danger(trans(response?.data?.message));
    }
  } catch (e) {
    // handled by AuthTokenInterceptor => errorMessage()
  }
}
