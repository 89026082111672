// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_RunningTrackingConfig} from '@modules/Tracking/providers/TrackingProvider';

export interface _TrackingState {
  currentTrackings: Record<string, _RunningTrackingConfig>;
}

const trackingSlice = createSlice({
  name: 'tracking',
  initialState: {
    currentTrackings: {},
  } satisfies _TrackingState,
  reducers: {
    setCurrentTrackings(state, action) {
      logger.info('[TrackingSlice] Setting current trackings.');

      state.currentTrackings = action.payload;
    },
    resetCurrentTrackings(state, action) {
      logger.info('[TrackingSlice] Resetting current trackings.');

      state.currentTrackings = {};
    },
  },
});

export const {setCurrentTrackings, resetCurrentTrackings} = trackingSlice.actions;
export default trackingSlice;
