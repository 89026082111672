// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {
  saveScheduledEmailDraftAction,
  scheduleEmailAction,
  sendScheduledEmailAction,
} from '@modules/ContentEngine/util/emailsActionsUtil';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Button} from '@modules/Core/components/base/buttons/Button';
import DatePicker from '@modules/Core/components/base/datepicker/DatePicker';
import {Input} from '@modules/Core/components/base/inputs/Input';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {_ScheduledEmail, _ScheduledEmailStatus} from '@modules/Profile/types/emails.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile, getProfilePic} from '@modules/Profile/util/profileUtil';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {trans} from '@modules/Translations/util/i18n';
import {IconInfo} from '../../base/Icons/Icons';
import {ToolTip} from '../../base/ToolTip';

interface _Props {
  profile: _Profile;
  recipients?: string[];
  subject?: string;
  body?: string;
  actionLink?: string;
  actionText?: string;
  status: _ScheduledEmailStatus;
  scheduledFor?: string;
  onClosed: (status?: _ScheduledEmailStatus) => void;
  emailId?: string;
  isPreview?: boolean;
  availableEmails?: string[];
  allowExtraEmails?: boolean;
  consentLabel?: string;
  onBeforeSave?: () => Promise<Partial<_ScheduledEmail>> | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  onRecipientsChange?: (emails: string[]) => void;
  helpLabel?: string;
  withScheduling?: boolean;
}

export const SendNewEmailDialogBody: React.FC<_Props> = ({
  profile,
  recipients,
  subject: outerSubject,
  body: outerBody,
  actionLink: outerActionLink,
  actionText: outerActionText,
  status,
  scheduledFor,
  onClosed,
  emailId,
  isPreview,
  availableEmails,
  allowExtraEmails = true,
  consentLabel,
  onBeforeSave,
  disabled,
  readOnly,
  onRecipientsChange,
  helpLabel,
  withScheduling = true,
}) => {
  const snackbar = useSnackbar();
  const [emails, setEmails] = React.useState<string[]>([]);
  const [subject, setSubject] = React.useState<string>(outerSubject ?? '');
  const [body, setBody] = React.useState<string>(outerBody ?? '');
  const [actionLink, setActionLink] = React.useState<string>(outerActionLink ?? '');
  const [actionText, setActionText] = React.useState<string>(outerActionText ?? '');
  const [ReactQuill, setReactQuill] = React.useState<any>(null);

  const [show, setShow] = useState<boolean>(true);
  // const [calendarIntegration, setCalendarIntegration] = useState<string>(null);
  const [dates, setDates] = useState<Date[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);

  const allowActionLabelChange = false;

  useEffect(() => {
    if (scheduledFor) {
      setDates([new Date(scheduledFor)]);
    }
  }, [scheduledFor]);

  // Dynamically import ReactQuill on client side
  useEffect(() => {
    import('react-quill')
      .then(module => {
        setReactQuill(() => module.default);
      })
      .catch(error => {
        console.error('Failed to load ReactQuill:', error);
      });
  }, []);
  useEffect(() => {
    logger.debug('SendNewEmailDialogBody', {recipients});
    setEmails(recipients ?? []);
  }, [recipients]);

  const onScheduleClick = (): void => {
    setCurrentPage(prev => (prev === 0 ? 1 : 0));
  };
  const onSendNowClick = async () => {
    try {
      const overrideData = (await onBeforeSave?.()) ?? {};
      if (overrideData === undefined) {
        return;
      }
      // Call the action to send the email now
      const emailData: Partial<_ScheduledEmail> = {
        profile_id: currentProfile()?.id,
        institution_id: currentProfile()?.institution_id,
        emails,
        email_text: body,
        email_subject: subject,
        scheduled_at: dates.length > 0 ? dates[0].toISOString() : undefined,
        action_link: actionLink,
        action_text: actionText,
        ...overrideData,
      };

      // Call the action to send the email now with all necessary fields
      const success = await sendScheduledEmailAction(emailId, emailData);

      if (success) {
        // Display success feedback
        snackbar.success(trans('emails.send_now_success'));
        // Optionally reload or refresh the content
        reloadContentEngine();
        onClosed?.('sent');
      } else {
        // Display error feedback
        snackbar.danger(trans('emails.send_now_error'));
      }
    } catch (e) {
      // Handle unexpected errors (likely already logged by `sendScheduledEmailAction`)
      snackbar.danger(trans('emails.send_now_unexpected_error'));
    }
  };

  const onScheduleEmail = async () => {
    logger.debug('Schedule email:', {dates});

    // Validation checks
    if (dates.length === 0) {
      snackbar.danger(trans('emails.errors.no_schedule_date'));
      return;
    }
    if (emails.length === 0) {
      snackbar.danger(trans('emails.errors.no_recipients'));
      return;
    }
    if (!subject || !body) {
      snackbar.danger(trans('emails.errors.subject_body_required'));
      return;
    }

    const overrideData = (await onBeforeSave?.()) ?? {};
    if (overrideData === undefined) {
      return;
    }
    try {
      const params = {
        id: emailId,
        recipients: emails,
        subject,
        body,
        actionLink: actionLink || overrideData.action_link,
        actionText,
        scheduledFor: dates[0].toISOString(), // Convert the selected date to ISO string
        profileId: currentProfile()?.id,
        institutionId: currentProfile()?.institution_id,
      };

      const success = await scheduleEmailAction(params);

      if (success) {
        // Display success feedback
        snackbar.success(trans('emails.schedule_success'));
        // Optionally reload or refresh the content
        reloadContentEngine();
        onClosed?.('scheduled');
        // Reset the form or navigate away if necessary
      } else {
        // Display error feedback
        snackbar.danger(trans('emails.schedule_error'));
      }
    } catch (e) {
      // Handle unexpected errors
      snackbar.danger(trans('emails.schedule_unexpected_error'));
      logger.error('An unexpected error occurred while scheduling the email:', e);
    }
  };

  const onSaveForLater = async () => {
    // Validation checks
    if (emails.length === 0) {
      snackbar.danger(trans('emails.errors.no_recipients'));
      return;
    }
    if (!subject || !body) {
      snackbar.danger(trans('emails.errors.subject_body_required'));
      return;
    }

    const overrideData = (await onBeforeSave?.()) ?? {};

    if (overrideData === undefined) {
      return;
    }
    logger.debug('[TextEmailsStep] inviteMembersAction result', overrideData);
    try {
      const params = {
        id: emailId,
        recipients: emails,
        subject,
        body,
        actionLink: overrideData.action_link ?? actionLink,
        actionText,
        // Include scheduledFor if a date has been selected
        scheduledFor: dates.length > 0 ? dates[0].toISOString() : undefined,
      };

      const savedDraft = await saveScheduledEmailDraftAction(params);

      if (savedDraft) {
        // Display success feedback
        snackbar.success(trans('emails.draft_saved_success'));
        // Optionally reload or refresh the content
        reloadContentEngine();
        onClosed?.('draft');
        // Optionally close the dialog or reset the form
      } else {
        // Display error feedback
        snackbar.danger(trans('emails.draft_saved_error'));
      }
    } catch (e) {
      // Handle unexpected errors
      snackbar.danger(trans('emails.draft_saved_unexpected_error'));
      logger.error('An unexpected error occurred while saving the email draft:', e);
    }
  };

  const onSetEmails = (emails: string[]) => {
    setEmails(emails);
    onRecipientsChange?.(emails);
  };

  const page0Content = (
    <>
      <Emails
        autocompleteEmails={availableEmails ?? undefined}
        disabled={isPreview || !allowExtraEmails}
        emails={emails}
        setEmails={onSetEmails}
        label={trans('emails.recipients')}
        consentLabel={consentLabel}
        readOnly={readOnly}
        helpLabel={helpLabel}
      />

      <Input disabled={isPreview} label={trans('emails.subject')} value={subject} onChange={e => setSubject(e)} />

      <div className="w-full">
        <div className="w-full mb-1">
          <label htmlFor="message" className="text-body2-bold">
            {trans('base.message')}
            <ToolTip html={trans('emails.message_help_label')} plc="bottom-right" className="mb-[-5px] ml-[5px]">
              <IconInfo />
            </ToolTip>
          </label>
        </div>
        {ReactQuill && (
          <ReactQuill id="message" className="w-full" readOnly={isPreview} defaultValue={body} onChange={setBody} />
        )}
      </div>

      {allowActionLabelChange && (
        <div className="flex flex-row w-full justify-between items-center gap-3">
          <Input
            disabled={isPreview}
            label={trans('emails.action_text')}
            value={actionText}
            onChange={e => setActionText(e)}
          />
          <Input label={trans('emails.action_link')} value={actionLink} disabled onChange={e => setActionLink(e)} />
        </div>
      )}
    </>
  );

  const page1Content = (
    <DatePicker
      description={<div dangerouslySetInnerHTML={{__html: trans('emails.email_tip')}} />}
      options={{
        onlySingleDate: true,
        // either 9am today, or now if after 9am
        minDate: new Date(new Date().setHours(new Date().getHours() < 9 ? 9 : 0)),
      }}
      show={show}
      setShow={setShow}
      dates={dates}
      setDates={setDates}
    />
  );

  logger.debug('SendNewEmailDialogBody', {currentPage, isPreview, scheduledFor});
  return (
    <div className="flex flex-col items-center w-full gap-3">
      {currentPage === 0 ? page0Content : page1Content}
      {!isPreview && (
        <div className="flex flex-row w-full justify-between items-center gap-3">
          {withScheduling && (
            <div className="flex flex-row flex-grow justify-start items-center gap-3">
              {status === 'draft' && (
                <Button
                  disabled={disabled}
                  width="relative-col-width-2"
                  variant="secondary"
                  label={trans('emails.save_for_later')}
                  onClick={onSaveForLater}
                />
              )}
            </div>
          )}
          <div className="flex flex-row flex-grow justify-end items-center gap-3">
            {withScheduling && (
              <Button
                disabled={disabled}
                width="relative-col-width-2"
                variant="secondary"
                label={currentPage === 0 ? trans('emails.schedule') : trans('base.back')}
                onClick={onScheduleClick}
              />
            )}
            <Button
              disabled={disabled}
              width="relative-col-width-2"
              label={currentPage === 0 ? trans('emails.send_now') : trans('emails.schedule_email')}
              onClick={currentPage === 0 ? onSendNowClick : onScheduleEmail}
            />
          </div>
        </div>
      )}
    </div>
  );
};
