// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {Section} from '@modules/Core/components/layout/Section';
import {_Gender} from '@modules/Profile/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  dateOfBirth: string;
  setDateOfBirth: (value: string) => void;
  phone: string;
  setPhone: (value: string) => void;
  gender: _Gender;
  setGender: (value: _Gender) => void;
  position: string;
  setPosition: (value: string) => void;
  onChanges?: () => void;
}

const options = [
  {value: 'profile.female', label: 'profile.female'},
  {value: 'profile.male', label: 'profile.male'},
  {value: 'profile.other', label: 'profile.other'},
  {value: 'profile.no_answer', label: 'profile.no_answer'},
];

export const About: React.FC<_Props> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dateOfBirth,
  setDateOfBirth,
  phone,
  setPhone,
  gender,
  setGender,
  position,
  setPosition,
  onChanges,
}) => {
  return (
    <Section title={trans('profile.about_you')}>
      <div className="flex flex-row gap-3">
        <Input
          label={trans('profile.first_name')}
          value={firstName}
          onChange={value => {
            setFirstName(value);
            onChanges?.();
          }}
          width="relative-col-width-5"
          placeholder={trans('profile.first_name')}
        />
        <Input
          label={trans('profile.last_name')}
          value={lastName}
          onChange={value => {
            setLastName(value);
            onChanges?.();
          }}
          width="relative-col-width-5"
          placeholder={trans('profile.first_name')}
        />
      </div>

      <div className="flex flex-row gap-3">
        <Input
          label={trans('profile.birthday')}
          value={dateOfBirth}
          onChange={(value): void => {
            setDateOfBirth(value);
            onChanges?.();
          }}
          width="relative-col-width-5"
          placeholder="mm/dd/yyyy"
          type="date"
        />

        <div className="relative-col-width-5">
          <Select
            options={options.map(option => ({value: option.value, label: trans(option.label)}))}
            label={trans('profile.gender')}
            onChange={(event): void => {
              setGender(event.value as _Gender);
              onChanges?.();
            }}
            value={{value: gender}}
          />
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <Input
          label={trans('profile.telephone')}
          value={phone}
          onChange={(value): void => {
            setPhone(value);
            onChanges?.();
          }}
          width="relative-col-width-5"
        />

        <Input
          label={trans('profile.position')}
          value={position}
          onChange={(value): void => {
            setPosition(value);
            onChanges?.();
          }}
          width="relative-col-width-5"
        />
      </div>

      <span>{trans('base.profile_basicInfo_consent')}</span>
    </Section>
  );
};
