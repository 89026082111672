// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {_CandidateAnalysis} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {saveCandidateAnalysisAction} from '@modules/CandidateAnalysis/util/candidateAnalysisFlowActions';
import {
  _ProfileFeedbackItemProps,
  ProfileFeedbackItem,
} from '@modules/ContentEngine/components/items/profile/common/ProfileFeedbackItem';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {TextArea} from '@modules/Core/components/base/inputs/TextArea';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  analysis?: _CandidateAnalysis | undefined | null;
  analysisId?: boolean;
  feedbackProps: _ProfileFeedbackItemProps;
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

export const CandidateAnalysisCreatorItem: React.FC<_Props> = ({
  analysis: externalAnalysis = undefined,
  feedbackProps = {},
  context,
  tutorialKey,
  tutorialService,
}) => {
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();
  const [analysis, setAnalysis] = useState<_CandidateAnalysis | null | undefined>(undefined);
  const initialLoaded = useRef(false);

  useEffect(() => {
    if (!initialLoaded.current && externalAnalysis !== undefined) {
      setAnalysis(externalAnalysis);
      initialLoaded.current = true;
    }
  }, [externalAnalysis]);
  const onSave = useCallback(async (): Promise<void> => {
    if (!analysis) {
      return;
    }

    const errors = validateAnalysis();
    if (errors?.length) {
      snackbar.danger(trans(errors[0]));
    }
    const newAnalysisId = await saveCandidateAnalysisAction(analysis, analysis?.id);

    if (newAnalysisId) {
      snackbar.success(trans('candidate_analysis.save_success'));
      setAnalysis({...analysis, id: newAnalysisId});
      navigateToFlow('CandidateAnalysis');
    }
  }, [analysis]);

  const validateAnalysis = (): string[] | undefined => {
    // Check title available
    if (!analysis?.title) {
      return ['candidate_analysis.errors.title_missing'];
    }
  };

  return (
    <CustomizableSectionItem context={context} tutorialService={tutorialService} tutorialKey={tutorialKey}>
      <div className="flex flex-col w-full items-center gap-3">
        <Input
          placeholder={trans('candidate_analysis.title_placeholder')}
          value={analysis?.title}
          onChange={e => {
            setAnalysis({...analysis, title: e});
          }}
        />

        <TextArea
          placeholder={trans('candidate_analysis.description_placeholder')}
          value={analysis?.description}
          onChange={e => {
            setAnalysis({...analysis, description: e.target.value ?? ''});
          }}
        />
      </div>
      <div className="flex flex-row justify-between items-center w-full ">
        <Button disabled={!analysis} width="relative-col-width-2" onClick={onSave}>
          {trans('base.save')}
        </Button>
      </div>
    </CustomizableSectionItem>
  );
};
