// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {FlagDropDown} from '@modules/Core/components/base/FlagDropDown';
import {IconSchedule} from '@modules/Core/components/base/Icons/Icons';
import {Logo} from '@modules/Core/components/base/Logo';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {goToCoachingSession} from '@modules/Core/util/routingUtil';
import {TopBarNotifications} from '@modules/Notifications/components/TopBarNotifications';
import UpgradePlanButton from '@modules/Profile/components/payments/UpgradePlanButton';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';
import {TopBarUser} from './TopBarUser';
// The TopBarBackground is a simple div that only creates the background color along the entire width of the screen.
// it can't be part of the global grid as all elements inside the global grid are limited to the max width of the global grid.

// The TopBar itself should only be as big as the grid but its background should span the entire screen width.
// Therefore, the two have to be separated. To achieve that the Topbar is above the TopBarBackground, its margin-top is set to a negative value to make it overlap the TopBarBackground.

// not part of the main grid

export const TopBarBackground: React.FC = () => (
  <div className="flex flex-shrink-0 sticky top-[-1px] z-20 w-screen bg-white500 topBar-height shadow-2" />
);

interface _TopBarProps {
  showLogo?: boolean;
}

// part of the main grid -> stuff like custom-col-width works
export const TopBar: React.FC<_TopBarProps> = ({showLogo = true}) => {
  const [currentProfile] = useProfile();
  const {currentLanguage, setLanguage} = useLanguage();

  return (
    <div className="sticky top-[-1px] z-20">
      <div className="w-full flex items-start justify-end topBarContainer z-50 topBar-cont">
        {showLogo && (
          <div className="h-8 flex items-center justify-start pl-4 w-full">
            <Logo href="/" />
          </div>
        )}
        <div className="shrink-0 topBar-height bg-white500 flex items-center gap-2 pt-[12px] pb-[12px]">
          {currentProfile && (
            <div className="flex items-end h-full gap-1">
              <ToolTip
                txt={can('organisation.details:edit') ? trans('base.book_a_coaching_session_tooltip') : undefined}
                plc="bottom"
              >
                <Button
                  label={trans('base.book_a_coaching_session')}
                  iconPosition="left"
                  icon={IconSchedule}
                  onClick={() => goToCoachingSession()}
                  width="relative-col-width-2"
                  size="sm"
                />
              </ToolTip>
              <UpgradePlanButton source="profile" width="relative-col-width-2" size="sm" />
            </div>
          )}

          <div className="flex items-end h-full">
            <FlagDropDown profileLang={currentLanguage} onLanguageChange={setLanguage} />
          </div>

          {currentProfile && (
            <div className="flex flex-row gap-2 justify-end items-end ">
              <TopBarNotifications />

              <TopBarUser profile={currentProfile} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
