// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {usePathname} from 'next/navigation';
import {
  IconAdd,
  IconCreditCard,
  IconCycle,
  IconEmail,
  IconFactionary,
  IconFlow,
  IconHome,
  IconLogout,
  IconNote,
  IconOrganisation,
  IconProfile,
  IconProfilePrivacy,
  IconSettings,
  IconShare,
  IconSmile,
  IconSocialRole,
  IconSurvey,
  IconTaskCompleter,
  IconTeam,
  IconTeamAnalysis,
  IconTeamDevelopment,
} from '@modules/Core/components/base/Icons/Icons';
import type {RouteValue} from '../types/routes';

// const query = useSearchParams();

export const useGetIsActiveRoute = (pattern: string | string[] | null | undefined): boolean => {
  const pathname = usePathname();
  return getIsActiveRoute(pathname, pattern);
};

export const getIsActiveRoute = (pathname: string, pattern: string | string[] | null | undefined): boolean => {
  if (!pattern) return false;

  if (Array.isArray(pattern)) {
    // list of regex patterns
    // try to match each regex pattern
    return pattern.some(p => pathname.match(p));
  }

  return Boolean(pathname.match(pattern));
};

type RouteIconMap = {
  [K in RouteValue]: React.ElementType;
};

export const getRouteIcon = (routeValue: string): React.ElementType => {
  const ROUTE_ICON_MAP: Partial<RouteIconMap> = {
    dashboard: IconHome,
    softfacts: IconSmile,
    '360-feedback': IconCycle,
    'shared-results': IconShare,
    'profile-exercises': IconTaskCompleter,
    journal: IconNote,
    'team.new': IconAdd,
    'organisation.new': IconAdd,
    softfactionary: IconFactionary,
    login: IconLogout,
    logout: IconLogout,
    profile: IconProfile,
    membership: IconCreditCard,
    settings: IconSettings,
    account: IconProfile,
    privacy: IconProfilePrivacy,
    organisation: IconFlow,
    'organisation.switch': IconCycle,
    notifications: IconCycle,
    'Design system': IconSocialRole,
    'surveys.custom': IconSurvey,
    'organisation.dashboard': IconHome,
    'organisation.structure': IconOrganisation,
    'organisation.members': IconTeam,
    'emails.scheduling': IconEmail,
  } as const;

  const TEAM_RELATED_PATTERNS = {
    team: 'team_',
    teamAnalysis: 'team.analysis_',
    teamDevelopment: 'team.development_',
    teamMembers: 'team.members_',
  } as const;

  const DEFAULT_ICON = IconHome;

  // Check for exact matches first
  const exactMatch = ROUTE_ICON_MAP[routeValue as RouteValue];
  if (exactMatch) return exactMatch;

  // Check for team-related patterns
  if (routeValue.includes(TEAM_RELATED_PATTERNS.team)) return IconTeam;
  if (routeValue.includes(TEAM_RELATED_PATTERNS.teamAnalysis)) return IconTeamAnalysis;
  if (routeValue.includes(TEAM_RELATED_PATTERNS.teamDevelopment)) return IconTeamDevelopment;
  if (routeValue.includes(TEAM_RELATED_PATTERNS.teamMembers)) return IconTeam;

  // Default icon
  return DEFAULT_ICON;
};
