// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {_ActionsTableHeaderSelectValue} from '@modules/Core/components/base/table/actions/ActionsTableHeader';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';

export type _SelectedProps = Record<string, any>;
export const useTableHeaderActions = (
  rows: Record<string, any>,
  getAllowedActions: (props: _SelectedProps) => _ActionsTableHeaderSelectValue[],
  getSelectedProps: (selectedRowsIds: string[], rows: Record<string, any>) => _SelectedProps,
  canSelectRow?: (row: any, props?: _SelectedProps) => boolean,
  defaultProps: Record<string, any> = {},
  rowUnselectableMessage: string | null = null
): {
  currentProps: _SelectedProps;
  selectedRowsIds: string[];
  actions: _ActionsTableHeaderSelectValue[];
  onClearSelection: () => void;
  onRowSelected: (rowId: string) => void;
  canSelectRow?: (row: any) => boolean;
  onSelectAll: () => void;
} => {
  const snackbar = useSnackbar();
  const [selectedRowsIds, setSelectedRowsIds] = useState<string[]>([]);
  const [actions, setActions] = useState<_ActionsTableHeaderSelectValue[]>([]);

  // This holds the relevant info of the current sub, used to dictate wether a member can be selected or not
  const [currentProps, setCurrentProps] = useState<_SelectedProps>(defaultProps);

  // Sets actions depending on the current subscription and the chosen profiles
  useEffect(() => {
    if (!getAllowedActions) {
      return;
    }
    const options: _ActionsTableHeaderSelectValue[] = getAllowedActions(currentProps);
    setActions(options);
  }, [currentProps]);

  // Sets actions depending on the current subscription and the chosen profiles
  useEffect(() => {
    if (!getSelectedProps) {
      return;
    }
    setCurrentProps(getSelectedProps(selectedRowsIds, rows));
  }, [selectedRowsIds, rows]);

  const onRowSelected = (rowId: string): void => {
    const row = rows[rowId];
    if (canSelectRow && !canSelectRow(row, currentProps)) {
      return;
    }
    if (canSelectRow && !canSelectRow(row, currentProps)) {
      if (rowUnselectableMessage) {
        snackbar.warning(rowUnselectableMessage);
      }
      return;
    }

    let updatedChecked = [...selectedRowsIds];
    if (selectedRowsIds?.includes(rowId)) {
      updatedChecked = updatedChecked.filter(id => id !== rowId);
    } else {
      updatedChecked.push(rowId);
    }
    setSelectedRowsIds(Array.from(new Set(updatedChecked)));
  };

  const onClearSelection = (): void => setSelectedRowsIds([]);

  const onSelectAll = (): void => {
    setSelectedRowsIds(Object.keys(rows));
  };

  const canSelectRowExternal = (row: any): boolean => !canSelectRow || canSelectRow(row, currentProps);

  return {
    currentProps,
    selectedRowsIds,
    actions,
    onClearSelection,
    onRowSelected,
    canSelectRow: canSelectRowExternal,
    onSelectAll,
  };
};
