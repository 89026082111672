// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconAdd, IconNote} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import CreateJournalForm from '@modules/Profile/components/journals/CreateJournalForm';
import {trans} from '@modules/Translations/util/i18n';

interface _CreateJournalButtonProps {
  onUpdateJournals?: (category: string) => void;
  icon?: React.ElementType;
  selectedGroupCategory?: string | null;
  teamId?: string | null;
  filterCategoryId?: string | null;
  selectedGroup?: string;
  metadata?: Record<string, any>;
  onClose?: () => void;
  exerciseView?: boolean;
  size?: 'sm' | 'md' | 'lg';
  withRefresh?: boolean;
  width?: string;
}

export const CreateJournalButton: React.FC<_CreateJournalButtonProps> = ({
  onUpdateJournals,
  icon,
  selectedGroupCategory,
  teamId,
  filterCategoryId,
  selectedGroup,
  metadata,
  onClose,
  exerciseView,
  size = 'lg',
  width,
}) => {
  const [isJournalFormOpen, setIsJournalFormOpen] = useState<boolean>(false);
  const handleOpenForm = (): void => {
    setIsJournalFormOpen(true);
  };

  const handleCloseForm = async (): Promise<void> => {
    onClose?.();
    setIsJournalFormOpen(false);
  };

  return (
    <>
      <ButtonComponent width={width} icon={icon} size={size} handleOpenForm={handleOpenForm} />
      {isJournalFormOpen && (
        <CreateJournalForm
          exerciseView={exerciseView}
          isOpen={isJournalFormOpen}
          onClose={handleCloseForm}
          onUpdateJournals={onUpdateJournals && onUpdateJournals}
          selectedGroupCategory={selectedGroupCategory}
          teamId={teamId}
          selectedGroup={selectedGroup}
          filterCategoryId={filterCategoryId}
          metadata={metadata}
        />
      )}
    </>
  );
};

interface _ButtonComponentProps {
  icon?: React.ElementType;
  size: 'sm' | 'md' | 'lg';
  handleOpenForm: () => void;
  width?: string;
}

const ButtonComponent: React.FC<_ButtonComponentProps> = ({icon: Icon, size, handleOpenForm}) => {
  if (Icon) {
    return (
      <ToolTip txt={trans('profile.journal.add_btn')} plc="bottom">
        <IconButton color="no-background" dusk="add_note_btn" onClick={handleOpenForm} icon={Icon} size={size} />
      </ToolTip>
    );
  }
  return (
    <ToolTip txt={trans('profile.journal.add_btn')} plc="bottom">
      <Button icon={Icon} dusk="add_note_btn" onClick={handleOpenForm} variant="primary">
        {trans('profile.journal.create_btn')}
      </Button>
    </ToolTip>
  );
};
