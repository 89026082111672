// @ts-nocheck
/* eslint-disable */

import React, {useState, useEffect} from 'react';
import {
  DragEndEvent,
  DragOverlay,
  DndContext,
  closestCenter,
  useDroppable,
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  verticalListSortingStrategy,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {createPortal} from 'react-dom';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Section} from '@modules/Core/components/layout/Section';
import {performFlowAction} from '@modules/FlowEngine/services/api';

interface Entry {
  key: string;
  title: string;
}

const SortableItem = ({entry}: {entry: Entry}) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: entry.key});

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.2 : 1,
        cursor: 'grab',
        marginBottom: '8px',
      }}
      {...attributes}
      {...listeners}
    >
      <Card>{entry.title}</Card>
    </div>
  );
};

const Container = ({id, children, className}: {id: string; children: React.ReactNode; className?: string}) => {
  const {setNodeRef} = useDroppable({id});
  return (
    <div ref={setNodeRef} className={className}>
      {children}
    </div>
  );
};

const LISTS = {
  AVAILABLE: 'available',
  SELECTED: 'selected',
} as const;

export const SelectEntriesItem = ({
  entries,
  selectedKeys = [],
  updateAction,
  availableLabel = 'Available Entries',
  selectedLabel = 'Selected Entries',
}: {
  entries: Entry[];
  selectedKeys?: string[];
  updateAction?: string;
  availableLabel?: string;
  selectedLabel?: string;
}) => {
  const [selected, setSelected] = useState<string[]>(selectedKeys);
  const [availableOrder, setAvailableOrder] = useState<string[]>(() => {
    return entries.filter(entry => !selectedKeys.includes(entry.key)).map(entry => entry.key);
  });
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {coordinateGetter: sortableKeyboardCoordinates})
  );

  const {selectedEntries, availableEntries} = React.useMemo(() => {
    const selectedItems = selected
      .map(key => entries.find(entry => entry.key === key))
      .filter((entry): entry is Entry => entry !== undefined);

    const availableItems = availableOrder
      .map(key => entries.find(entry => entry.key === key))
      .filter((entry): entry is Entry => entry !== undefined);

    return {selectedEntries: selectedItems, availableEntries: availableItems};
  }, [entries, selected, availableOrder]);

  const moveItem = (draggedId: string, isToSelected: boolean) => {
    if (isToSelected) {
      setSelected(prev => [...prev, draggedId]);
      setAvailableOrder(prev => prev.filter(id => id !== draggedId));
    } else {
      setSelected(prev => prev.filter(id => id !== draggedId));
      setAvailableOrder(prev => [...prev, draggedId]);
    }
  };

  const handleDragOver = ({active, over}: DragEndEvent) => {
    if (!over) return;

    const draggedId = String(active.id);
    const overId = String(over.id);
    const isFromSelected = selected.includes(draggedId);
    const isToSelected = overId === LISTS.SELECTED || selected.includes(overId);

    if (isFromSelected !== isToSelected) {
      moveItem(draggedId, isToSelected);
    }
  };

  useEffect(() => {
    if (updateAction) {
      const updateSelection = async () => {
        try {
          await performFlowAction({method: updateAction, params: {selectedKeys: selected}});
        } catch (error) {
          console.error('Failed to update selection:', error);
        }
      };
      updateSelection();
    }
  }, [selected, updateAction]);

  const handleDragEnd = async (event: DragEndEvent) => {
    const {active, over} = event;
    setActiveId(null);

    if (!over) return;

    const draggedId = String(active.id);
    const overId = String(over.id);
    const isFromSelected = selected.includes(draggedId);
    const isToSelected = overId === LISTS.SELECTED || selected.includes(overId);

    if (isFromSelected === isToSelected) {
      const items = isFromSelected ? selected : availableOrder;
      const setItems = isFromSelected ? setSelected : setAvailableOrder;

      const oldIndex = items.indexOf(draggedId);
      const newIndex = items.indexOf(overId);
      const updatedItems = arrayMove(items, oldIndex, newIndex);
      setItems(updatedItems);
    }
  };

  const activeEntry = activeId ? entries.find(entry => entry.key === activeId) : null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({active}) => setActiveId(String(active.id))}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={() => setActiveId(null)}
    >
      <div className="flex flex-row gap-3">
        {[
          {id: LISTS.AVAILABLE, title: availableLabel, items: availableEntries, sortItems: availableOrder},
          {id: LISTS.SELECTED, title: selectedLabel, items: selectedEntries, sortItems: selected},
        ].map(({id, title, items, sortItems}) => (
          <div key={id} className="relative-col-width-5 flex flex-col">
            <Section fullWidth fullHeight className="flex flex-col h-full" title={title} titleVariant="h6">
              <Container id={id} className="h-[600px] overflow-y-auto">
                <SortableContext items={sortItems} strategy={verticalListSortingStrategy}>
                  {items.map(entry => (
                    <SortableItem key={entry.key} entry={entry} />
                  ))}
                </SortableContext>
              </Container>
            </Section>
          </div>
        ))}
      </div>

      {createPortal(
        <DragOverlay zIndex={9999}>{activeEntry && <Card>{activeEntry.title}</Card>}</DragOverlay>,
        document.body
      )}
    </DndContext>
  );
};

export default SelectEntriesItem;
