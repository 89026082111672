// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SoftFactTab} from '@modules/Core/components/base/softfacts/tab/SoftFactTab';
import {
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_TEAM_MOTIVES,
  SERVICE_TEAM_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_TEAM_ROLES,
  SERVICE_TEAM_VALUES,
  SERVICE_TEAM_WAY_OF_WORKING,
} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {currentProfile, getServiceIcon} from '@modules/Profile/util/profileUtil';
import {isServiceDone, isServiceDoneV2, isServiceReady, isSurveyDone} from '@modules/Profile/util/serviceUtil';
import {_TeamAnalysisResultType} from '@modules/Team/types/teamanalysis.model';
import {trans} from '@modules/Translations/util/i18n';

interface _TeamAnalysisNavigationItemProps {
  teamId: string;
  service: string;
}

interface _TeamAnalysisEntry {
  key: string;
  title: string;
  service: _TeamAnalysisResultType;
  profileService: string;
}

const tabEntries: _TeamAnalysisEntry[] = [
  {
    key: 'personality',
    title: 'dashboard.entries.personality.title',
    service: SERVICE_TEAM_PROFILE_BASE_AND_ANALYSIS,
    profileService: SERVICE_PROFILE_BASE_AND_ANALYSIS,
  },
  {
    key: 'roles',
    title: 'dashboard.entries.roles.title',
    service: SERVICE_TEAM_ROLES,
    profileService: SERVICE_PROFILE_ROLES,
  },
  {
    key: 'values',
    title: 'dashboard.entries.values.title',
    service: SERVICE_TEAM_VALUES,
    profileService: SERVICE_PROFILE_VALUES,
  },
  {
    key: 'wayOfWorking',
    title: 'dashboard.entries.way_of_working.title',
    service: SERVICE_TEAM_WAY_OF_WORKING,
    profileService: SERVICE_PROFILE_WAY_OF_WORKING,
  },
  {
    key: 'motives',
    title: 'dashboard.entries.motives.title',
    service: SERVICE_TEAM_MOTIVES,
    profileService: SERVICE_PROFILE_MOTIVE_STRUCTURE,
  },
];

export const TeamAnalysisNavigationItem: React.FC<_TeamAnalysisNavigationItemProps> = ({
  service: activeService,
  teamId,
}) => {
  const navigateToFlow = useNavigateToFlow();

  const handleClick = (service: string): void => {
    console.log(service);
    navigateToFlow(FLOWS.TeamAnalysis, {
      service,
      teamId,
    });
  };

  return (
    <div className="flex flex-row w-full gap-3">
      {tabEntries
        .filter(item => isServiceReady(item.profileService) && isServiceDone(item.profileService))
        .map(item => {
          return (
            <div key={item.key} className="relative-col-width-3">
              <SoftFactTab
                label={trans(PROFILE_SERVICES_TITLES[item.profileService])}
                icon={getServiceIcon(item.profileService)}
                active={activeService === item.service}
                onChange={() => handleClick(item.service)}
              />
            </div>
          );
        })}
    </div>
  );
};
