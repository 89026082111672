// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {_TableColumns, _TableDataItem, Table} from '@modules/Core/components/base/table/Table';
import {Typography} from '@modules/Core/components/base/Typography';
import {formatDate} from '@modules/Core/util/dates';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ProfileSharedResult} from '@modules/Profile/types/shared-results.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {revokeSharedResultAction} from '@modules/Profile/util/sharedResultsActionsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileSharedResultsWithOthersTableProps {
  data: _ProfileSharedResult[];
}

export const ProfileSharedResultsWithOthersTable: React.FC<_ProfileSharedResultsWithOthersTableProps> = ({data}) => {
  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'relative-col-width-4-plus-gap'},
    {label: trans('base.shared_at'), key: 'created_at', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.analysis_type'), key: 'analysisType', width: 'relative-col-width-2-plus-gap'},
    {label: trans('base.action'), key: 'action', width: 'relative-col-width-2'},
  ];

  const renderRow = (row: _ProfileSharedResult): _TableDataItem => ({
    name: <AvatarUser label={row.profile?.fullName} image={getProfilePic(row.profile)} />,
    created_at: <Typography>{formatDate(new Date(row.created_at))}</Typography>,
    analysisType: trans(PROFILE_SERVICES_TITLES[row.type]),
    action: <Typography>{trans(row.viewed ? 'shared_results.viewed' : 'shared_results.not_yet_viewed')}</Typography>,
  });

  const onRevokeClicked = async (row: _ProfileSharedResult): Promise<void> => {
    const response = await revokeSharedResultAction(row.id);
    if (response) {
      reloadContentEngine();
    }
  };

  return (
    <Table
      data={data}
      columns={columns}
      renderRow={renderRow}
      filterOptions={{
        enabled: true,
        searchAttributes: ['profile.fullName', 'created_at', 'type'],
      }}
    />
  );
};
