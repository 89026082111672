// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {IconCopy} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {Section} from '@modules/Core/components/layout/Section';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {getCurrentFrontendBasePath} from '@modules/Core/util/util';
import {_Item} from '@modules/FlowEngine/components/Steps/SelectStep';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {selectLabel} from '@modules/FlowEngine/util/helpers/itemSelectionUtil';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export const SelectionEmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<_Item | null>(null);
  const {selectedItem: previouslySelectedItem, items, labelSelector} = options;
  const {currentInstitution} = useInstitution();
  const initialEmailsLoaded = useRef<boolean>(false);
  const teamsDivRef = useRef<HTMLDivElement>(null);
  const snackbar = useSnackbar();

  const inviteUrl = useMemo(() => {
    if (options?.teamId) {
      return `${getCurrentFrontendBasePath()}/register/${currentInstitution?.code}/${options?.teamId?.slice(0, 8)}`;
    }
    return `${getCurrentFrontendBasePath()}/register/${currentInstitution?.code}`;
  }, [currentInstitution, options]);

  useLayoutEffect(() => {
    scrollSelectIntoView();
  }, []);
  useEffect(() => {
    if (!initialEmailsLoaded.current) {
      setEmails(options.preselectedEmails ?? []);
      initialEmailsLoaded.current = true;
    }
  }, [options]);

  useEffect(() => {
    if (previouslySelectedItem) {
      setSelectedItem(previouslySelectedItem as _Item);
    }
  }, [previouslySelectedItem]);

  const getValuesCallback = (): Record<string, any> => ({
    selectedItem,
    emails,
  });

  const scrollSelectIntoView = () => {
    // Need timeout to wait for the Select DropDown to render
    setTimeout(() => {
      const element = teamsDivRef.current;
      if (!element) return;
      const dialogContainer = document.querySelector('[role="dialog-body"]');

      if (dialogContainer) {
        // Get the element's position relative to the dialog
        const elementRect = element.getBoundingClientRect();
        const containerRect = dialogContainer.getBoundingClientRect();
        const relativeTop = elementRect.top - containerRect.top;

        // Calculate space needed for dropdown (roughly 250px or adjust based on your needs)
        const dropdownSpace = 250;

        // Calculate the scroll position that would leave enough space for the dropdown
        const desiredScrollTop = dialogContainer.scrollTop + relativeTop - (containerRect.height - dropdownSpace);

        dialogContainer.scrollTo({
          top: Math.max(0, desiredScrollTop),
          behavior: 'smooth',
        });
      }
    });
  };

  const copyInviteUrl = (): void => {
    navigator.clipboard
      .writeText(inviteUrl)
      .then(() => {
        snackbar.success('Invite URL copied.');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
        snackbar.danger('Failed to copy invite URL.');
      });
  };

  logger.debug('[SelectionEmailsStep] options', {
    items,
    options,
    selectedItem,
    emails,
  });

  const selectItems = items?.map(item => {
    return {
      label: selectLabel(item, labelSelector),
      value: item.id,
    } as _SelectValue;
  });

  if (options.allowEmpty) {
    selectItems.unshift({label: options.emptyLabel ?? '-', value: null});
  }
  // Preload css class: text-body-bold
  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[selectedItem, emails]} options={options}>
      {!options.hideEmails && (
        <Section
          title={options.emailsTitle ?? null}
          description={options.emailsDescription}
          titleVariant="body-bold"
          containerGap={1}
          gap={2}
        >
          <Emails
            emails={emails}
            setEmails={setEmails}
            placeholder={options.emailsPlaceholder}
            label={options.emailsLabel}
            consentLabel={options.emailsConsentLabel}
          />
        </Section>
      )}

      {options.showInviteUrl && (
        <Section
          title={trans('invite.via_invite_link')}
          description={
            options?.teamId
              ? trans('invite.copy_invite_link_description_team')
              : trans('invite.copy_invite_link_description')
          }
          titleVariant="body-bold"
          containerGap={1}
          gap={2}
        >
          <Input value={inviteUrl} icon={IconCopy} iconPosition="right" onIconClick={() => copyInviteUrl()} readOnly />
        </Section>
      )}

      {!options.hideSelect && (
        <Section title={options.selectTitle ?? null}>
          <Select
            containerRef={teamsDivRef}
            disabled={options.selectionDisabled}
            value={{
              label: selectedItem ? selectLabel(selectedItem, labelSelector) : '',
              value: selectedItem?.id,
            }}
            onMenuOpen={scrollSelectIntoView}
            options={selectItems}
            onChange={v => setSelectedItem(items?.find(item => item.id === v.value) ?? null)}
            placeholder={options.selectPlaceholder}
            label={options.selectLabel}
          />
        </Section>
      )}
    </BaseStep>
  );
};
