// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_SnackbarActions} from '@modules/Core/hooks/ui/snackbar';
import {dispatchReactEvent, reloadContentEngine, reloadProfileState} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {uuid} from '@modules/Core/util/util';
import {appState, dispatchAction} from '@modules/State/util/util';
import {finishFlow, resetFlow, saveFlow, startFlow, updateStepFlowData, updateStepIndex} from '../../state/flowSlice';
import {_FlowAction} from '../../types/action.model';
import {_FlowInput, _FlowSchema, _FlowShowConditionResult} from '../../types/core.model';
import {_FlowOnFinish, _FlowOptions} from '../../types/flow.model';
import {_BaseStepComponentOptions, _FlowStep, _StepEvent} from '../../types/step.model';
import {FlowEvaluator} from '../evaluator/flowEvaluator';
import {getFlowData, getFlowStateByName} from '../helpers/dataGetters';
import {loadFlow} from '../helpers/loader';
import {extractStepValidators, getStepByIndex} from '../helpers/util';
import {RegisteredSteps} from '../registeredSteps';

export class FlowManager {
  private readonly id: string;

  private readonly flowSchema: _FlowSchema;

  private readonly flowName: string;

  private readonly instanceKey: string;

  private readonly input: _FlowInput;

  private readonly flowEvaluator: FlowEvaluator;

  private readonly onFinishCallback: (() => void) | undefined;

  private flowOptions: _FlowOptions;

  public readonly onNavigate?: (
    path: string,
    message?: string | undefined,
    severity?: string,
    reloadProfile?: boolean,
    withLoader?: boolean
  ) => void;

  public readonly snackbar?: _SnackbarActions | null;

  public readonly onStepChange?: (stepName: string) => void;

  private ready: boolean = false;

  constructor(
    flowName: string,
    instanceKey: string,
    flowSchema: _FlowSchema,
    input: _FlowInput | undefined,
    onNavigate?: (path: string, message?: string, severity?: string, reloadProfile?: boolean) => void,
    snackbar?: _SnackbarActions | null,
    onStepChange?: (stepName: string) => void,
    onFinishCallback?: () => void
  ) {
    this.id = uuid();
    this.flowName = flowName;
    this.instanceKey = instanceKey;
    this.flowSchema = flowSchema;
    this.onFinishCallback = onFinishCallback;
    this.input = input ?? ({} satisfies _FlowInput);
    this.onNavigate = onNavigate;
    this.snackbar = snackbar;
    this.onStepChange = onStepChange;
    this.flowEvaluator = new FlowEvaluator(this.flowName, this.instanceKey, this);
  }

  initFlow(): void {
    logger.debug('[FlowManager] initializing..', {
      instanceKey: this.instanceKey,
      flowName: this.flowName,
      isRunning: this.flowRunning(),
      input: this.input,
    });
    // TODO @Sherif check this
    const forceReset = true;
    if (!this.flowRunning() || forceReset) {
      const runningFlow = this.flowRunning() ? getFlowStateByName(`${this.flowName}_${this.instanceKey}`) : null;

      const flow = loadFlow(this.flowSchema, this.input, this.flowEvaluator);

      this.flowOptions = flow.options;

      if (runningFlow) {
        // replace steps data with existing data
        for (const step of Object.keys(flow.steps)) {
          if (runningFlow.steps[step]) {
            flow.steps[step].data = runningFlow.steps[step].data;
          }
        }
        // Do same for actions
        if (runningFlow.actions) {
          if (!flow.actions) {
            flow.actions = {};
          }
          for (const action of Object.keys(flow.actions ?? {})) {
            if (runningFlow.actions[action]) {
              flow.actions[action].data = runningFlow.actions[action].data;
            }
          }
        }
      }
      dispatchAction(resetFlow({name: `${this.flowName}_${this.instanceKey}`}));
      dispatchAction(
        startFlow({
          flow,
          name: `${this.flowName}_${this.instanceKey}`,
          stepName: this.input?.stepName || null,
        })
      );
    }

    if (this.isRedirectFlow() && this.flowRunning()) {
      logger.info('[FlowManager] Redirect flow detected');
      this.onFinishClicked();
    }
  }

  reInitFlow(stepName?: string): void {
    const runningFlow = getFlowStateByName(this.flowName, this.instanceKey);

    const flow = loadFlow(this.flowSchema, this.input, this.flowEvaluator);

    // replace steps data with existing data

    // if runningFlow is available and not empty
    if (runningFlow && Object.keys(runningFlow).length !== 0) {
      for (const step of Object.keys(flow.steps)) {
        if (runningFlow.steps[step]) {
          flow.steps[step].data = runningFlow.steps[step].data;
        }
      }
      // same for actions
      if (runningFlow.actions) {
        if (!flow.actions) {
          flow.actions = {};
        }
        for (const action of Object.keys(flow.actions ?? {})) {
          if (runningFlow.actions[action]) {
            flow.actions[action].data = runningFlow.actions[action].data;
          }
        }
      }
    }

    const step = stepName ?? runningFlow?.currentStep?.stepName ?? null;

    dispatchAction(
      startFlow({
        flow,
        name: `${this.flowName}_${this.instanceKey}`,
        stepName: step,
      })
    );
  }

  initStep(currentStep?: _FlowStep): boolean {
    if (!currentStep) {
      return false;
    }

    // If the step should not show, handle what to do according to stepConditions
    const flowResult = this.handleFlowConditions();
    const stepResult = flowResult && this.handleStepConditions(currentStep);

    logger.info(
      `[FlowManager] initStep => ${flowResult}:${stepResult}`,
      `${currentStep.flowName} -> ${currentStep.stepName}`
    );

    this.ready = stepResult;

    return stepResult;
  }

  isRedirectFlow(): boolean {
    return !Object.keys(this.flowSchema.steps).length;
  }

  handleFlowConditions(): boolean {
    const currentProfile = appState().profile?.currentProfile;

    // Check for type unauthorized
    if (this.flowSchema.type !== 'unauthorized' && !currentProfile) {
      return false;
    }

    // Have to handle show conditions here
    if (this.flowSchema.showConditions) {
      for (const showCondition of this.flowSchema.showConditions) {
        if (!showCondition.handle || !showCondition.expression) {
          logger.warn('[FlowManager] Show condition is missing handle or expression', {showCondition});
          continue;
        }
        const conditionResult = this.flowEvaluator.evaluateObjectFieldsInternal(
          showCondition,
          undefined,
          undefined,
          undefined,
          ['handle']
        ) as _FlowShowConditionResult;

        if (conditionResult && conditionResult.expression === false) {
          logger.info(`[FlowManager] Flow ${this.flowName} not shown because of show condition`, {
            showCondition,
            conditionResult,
          });

          if (conditionResult.handle) {
            this.flowEvaluator.doEvaluateExpression(conditionResult.handle);
          }
          return false;
        }
        logger.info(`[FlowManager] Flow ${this.flowName} shown because of show condition`, {
          showCondition,
          conditionResult,
        });
      }
    }

    return true;
  }

  handleStepConditions(currentStep: _FlowStep): boolean {
    const {steps} = getFlowData(this.flowName, this.instanceKey);
    const stepConditionsResult = this.flowEvaluator.evaluateStepConditions(currentStep);

    if (stepConditionsResult.show) {
      const stepType: string = currentStep.type;
      if (['survey', 'contentEngine'].includes(stepType)) {
        // skip navigation.done
        return true;
      }
      if (currentStep?.type === 'contentEngineItem') {
        const itemType: string = currentStep?.componentOptions?.item?.type?.replace(/'/g, '') ?? '';
        if (['profileResult', 'softfactionary'].includes(itemType)) {
          // skip navigation.done
          return true;
        }
      }

      const navigation = currentStep?.navigation ?? this.flowSchema.options?.navigation;

      const navigationType: string = navigation?.type?.replace(/'/g, '');
      if (['content_engine'].includes(navigationType)) {
        // skip navigation.done
        return true;
      }

      dispatchReactEvent('navigate.done', {currentStep});
      return true;
    }

    if (stepConditionsResult.nextStep) {
      const stepName = stepConditionsResult.nextStep;
      this.goToStepByName(stepName);
    } else if (currentStep.index < Object.keys(steps ?? {}).length - 1) {
      // If conditions for current step are not met, go to next step
      this.changeStep(currentStep.index + 1, false);
    } else {
      // If no next step is found, finish the flow
      this.onFinishClicked();
    }

    return false;
  }

  async executeFlowActions(stepActions: Record<string, _FlowAction>): Promise<Record<string, any>> {
    const {actions} = getFlowData(this.flowName, this.instanceKey);

    if (!actions || !stepActions) {
      return {};
    }

    const actionNames = Object.keys(stepActions);
    // get actions from actions dict whose name are in toBeExecutedActions

    const actionsToExecuteNames = Object.keys(actions ?? {}).filter(actionName => actionNames.includes(actionName));
    const actionsToExecute: Record<string, _FlowAction> = {};

    for (const actionName of actionsToExecuteNames) {
      actionsToExecute[actionName] = actions[actionName];
    }

    return await this.flowEvaluator.executeActions(this.flowName, this.instanceKey, actionsToExecute);
  }

  onFinishClicked(): void {
    const {currentStep, actions} = getFlowData(this.flowName, this.instanceKey);

    if (currentStep && !this.validateStepData(currentStep)) {
      return;
    }

    if (!this.flowRunning()) {
      logger.info(
        '[FlowManager] onFinishClicked: Flow is not running, skipping onFinish actions, flowName:',
        this.flowName,
        this.instanceKey
      );
      return;
    }

    const onFinishActions = Object.fromEntries(
      Object.entries(actions ?? {}).filter(([, action]) => action?.executeOnFinish !== false)
    );

    this.flowEvaluator
      .executeActions(this.flowName, this.instanceKey, onFinishActions)
      .then(res => {
        if (this.onFinishCallback) {
          this.onFinishCallback();
        }
        this.redirectAfterFlow();
        dispatchAction(finishFlow({name: `${this.flowName}_${this.instanceKey}`}));
      })
      .catch(err => {
        logger.error('[FlowManager] onFinishClicked failed', err);
      });
  }

  finishFlow(): void {
    this.flowEvaluator.finishFlow();
  }

  onUpdateFlowData(data: Record<string, any>): void {
    dispatchAction(updateStepFlowData({name: `${this.flowName}_${this.instanceKey}`, data}));
  }

  onNextClicked(currentStep: _FlowStep): void {
    this.changeStep(currentStep.index + 1, true);
  }

  onPreviousClicked(currentStep: _FlowStep): void {
    this.changeStep(currentStep.index - 1, false);
  }

  onStepChanged(newStep: _FlowStep): void {
    this.changeStep(newStep.index, false);
  }

  onSaveClicked(): void {
    dispatchAction(saveFlow({name: this.flowName}));
  }

  goToStepByName(stepName: string, executeEffects = true): void {
    const {steps} = getFlowData(this.flowName, this.instanceKey);

    if (!steps) {
      logger.error('[FlowManager] Steps not found.');
      return;
    }
    const stepIndex = Object.keys(steps).indexOf(stepName);
    logger.debug('[FlowManager] goToStepByName', {
      stepName,
      stepIndex,
      steps,
    });
    this.changeStep(stepIndex, executeEffects);
  }

  loadStepComponent(step: _FlowStep): React.ReactElement | null {
    if (!this.ready) {
      return null;
    }

    const {steps, input, actions} = getFlowData(this.flowName, this.instanceKey);
    const stepType = step.type;
    const stepComponent = RegisteredSteps[stepType];

    if (stepComponent === undefined) {
      throw new Error(`Step "${stepType}" is not registered`);
    }

    const events: Record<string, _StepEvent> = {};

    if (step?.events) {
      for (const eventName of Object.keys(step.events)) {
        const event = step.events[eventName];
        if (
          event.showConditions === null ||
          event.showConditions === undefined ||
          this.flowEvaluator.doEvaluateExpression(event.showConditions)
        ) {
          events[eventName] = this.flowEvaluator.evaluateObjectFieldsInternal(event, steps, input, actions, [
            'handle',
          ]) as _StepEvent;
        }
      }
    }

    const {validation} = step;

    const data = this.flowEvaluator.evaluateParams(steps, {}, input, actions);

    const options = this.flowEvaluator.evaluateObjectFieldsInternal(
      step.componentOptions,
      steps,
      input,
      actions
    ) as _BaseStepComponentOptions;

    options.name = step.stepName;
    options.index = step.index;
    options.flowName = this.flowName;

    options.flowTitle = this.flowEvaluator.evaluateObjectFieldsInternal(this.flowSchema.title);
    options.flowLayoutOptions = this.flowEvaluator.evaluateObjectFieldsInternal(this.flowSchema.layout);

    const getFieldValidators = (fieldName: string): any[] => {
      if (!validation) {
        return [];
      }

      return extractStepValidators(validation, stepComponent, fieldName);
    };

    const fireEvent = (eventName: string): any => {
      return this.flowEvaluator.evaluateStepEvent(events[eventName]);
    };

    // filter events dict to only include events that have show = true
    const showEvents: Record<string, _StepEvent> = {};

    for (const eventName of Object.keys(events)) {
      const event = events[eventName];
      if (event.show !== false) {
        showEvents[eventName] = event;
      }
    }

    options.getFieldValidators = getFieldValidators;
    options.fireEvent = fireEvent;
    options.events = showEvents;
    options.onComplete = () => this.onNextClicked(step);
    options.stepOptions = step.options || {};
    options.instanceKey = this.instanceKey;

    logger.info('React.createElement(stepComponent', {
      options,
      stepName: step.stepName,
      schema: this.flowSchema,
    });
    return React.createElement(stepComponent, {
      options,
      data,
    });
  }

  /**
   * Private
   */

  redirectAfterFlow(): void {
    const onFinish = this.flowEvaluator.evalFlowOnFinish() as _FlowOnFinish;

    logger.info('[FlowManager] onFinish Redirect', {
      flowName: this.flowName,
      IKey: this.instanceKey,
      handle: onFinish?.handle,
      redirect: onFinish?.redirect,
      onNavigate: this.onNavigate,
    });
    if (onFinish?.handle) {
      this.flowEvaluator.doEvaluateExpression(onFinish.handle);
    }

    if (!onFinish?.redirect) {
      if (onFinish?.reloadProfile) {
        reloadContentEngine();
        reloadProfileState();
      }
      if (onFinish?.alert) {
        this.snackbar?.show(onFinish.alert.message, onFinish.alert.severity);
      }
      return;
    }

    if (!this.onNavigate) {
      logger.warn('[FlowManager] onNavigate is not defined, cannot redirect after flow.');
      return;
    }

    this.onNavigate(
      onFinish?.redirect || '/',
      onFinish?.alert?.message,
      onFinish?.alert?.severity ?? 'success',
      onFinish?.reloadProfile
    );
  }

  flowRunning(): boolean {
    const flowState = getFlowStateByName(this.flowName, this.instanceKey);
    return !!(flowState && flowState?.finished === false);
  }

  validateStepData(currentStep: _FlowStep | undefined): boolean {
    const ready = currentStep?.data?.[currentStep.stepName]?.ready;

    // TODO (Low) Add steps, input, actions to evaluateObjectFieldsInternal
    const {errorMessage} = this.flowEvaluator.evaluateObjectFieldsInternal({
      errorMessage: currentStep?.componentOptions?.errorMessage,
    }) as {errorMessage?: string};

    if (ready !== undefined && !ready) {
      this.snackbar?.danger(errorMessage ?? 'Please fill all required fields');
      return false;
    }

    return true;
  }

  /**
   * IMPORTANT!!! Changing the step HAS to happen through this function, otherwise the step effects will not run
   * @param stepIndex
   * @param shouldExecuteEffects
   */
  changeStep(stepIndex: number, shouldExecuteEffects = true): void {
    const {currentStep, currentStepIndex, steps} = getFlowData(this.flowName, this.instanceKey);

    if (currentStep?.options?.loadOnStepChange) {
      logger.debug('[DBLoaderTest], navigate.start from changeStep', {
        flow: this.flowName,
        flowSchema: this.flowSchema,
        options: this.flowOptions,
        currentStep,
        stepOptions: currentStep?.options,
      });
      dispatchReactEvent('navigate.start', {step: currentStep, stepIndex});
    }

    if (shouldExecuteEffects && currentStep && !this.validateStepData(currentStep)) {
      return;
    }

    if (!steps) {
      logger.error(`[FlowManager] Steps not found, {steps: ${steps}}`);
      return;
    }
    const newStep = getStepByIndex(steps, stepIndex);

    logger.debug(
      `[FlowManager] Changing step from ${currentStep?.stepName} to ${newStep?.stepName},
            steps: ${JSON.stringify(Object.keys(steps))}, newStepIndex: ${stepIndex}`
    );

    if (stepIndex === currentStepIndex) {
      return;
    }

    if (stepIndex < 0) {
      logger.error(`Step index ${stepIndex} not found`);
      return;
    }

    // Had to use promise instead of wait to avoid changing all functions to async
    if (shouldExecuteEffects && currentStep?.actions) {
      void (async self => {
        try {
          await self.executeFlowActions(currentStep.actions ?? {});
          self.handleStepsEffects(currentStep, newStep, stepIndex, shouldExecuteEffects);
        } catch (e) {
          logger.error(e);
        }
      })(this);
    } else if (currentStep) {
      this.handleStepsEffects(currentStep, newStep, stepIndex, shouldExecuteEffects);
    }
  }

  nextStep(): void {
    const {currentStep} = getFlowStateByName(this.flowName, this.instanceKey) ?? {};
    if (!currentStep) {
      logger.error('[FlowManager] nextStep: currentStep or flow not found');
      return;
    }
    this.changeStep(currentStep.index + 1, true);
  }

  handleStepsEffects(
    currentStep: _FlowStep,
    newStep: _FlowStep | null,
    stepIndex: number,
    shouldExecuteEffects: boolean
  ): void {
    if (newStep?.onStepStarted) {
      this.flowEvaluator.evaluateStepEvent(newStep.onStepStarted);
    }

    if (shouldExecuteEffects && currentStep?.onStepFinished) {
      this.flowEvaluator.evaluateStepEvent(currentStep.onStepFinished);
    }

    logger.debug('[FlowManager] Changing step :', {
      currentStep,
      newStep,
      stepIndex,
      shouldExecuteEffects,
      steps: getFlowData(this.flowName, this.instanceKey).steps,
    });

    if (newStep) {
      dispatchAction(updateStepIndex({index: stepIndex, name: `${this.flowName}_${this.instanceKey}`}));
      const {steps} = getFlowData(this.flowName, this.instanceKey);

      logger.debug('[FlowManager] Changed step :', {
        steps,
        newStep,
      });

      if (this.onStepChange) {
        this.onStepChange(newStep?.stepName);
      }
    } else {
      this.onFinishClicked();
    }
  }
}
