// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {deleteScheduledEmailAction, sendScheduledEmailAction} from '@modules/ContentEngine/util/emailsActionsUtil';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {IconEditText, IconMore, IconSend, IconTrash, IconZoomIn} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import {SendNewEmailDialogBody} from '@modules/Core/components/platform/dialogs/SendNewEmailDialogBody';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {_ScheduledEmail, _ScheduledEmailStatus} from '@modules/Profile/types/emails.model';
import {currentProfile, getProfilePic} from '@modules/Profile/util/profileUtil';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  email: _ScheduledEmail;
  onChangeTab: (tab: _ScheduledEmailStatus) => void;
}

const statusLangProps: Record<string, string> = {
  draft: 'emails.status.draft',
  scheduled: 'emails.status.scheduled',
  sent: 'emails.status.sent',
};

const colors: Record<_ScheduledEmailStatus, 'dark' | 'success' | 'disabled'> = {
  draft: 'disabled',
  scheduled: 'dark',
  sent: 'success',
};

export const EmailsOverviewCard: React.FC<_Props> = ({onChangeTab, email}) => {
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();

  // State for managing the dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<React.ReactNode>(null);

  const onDialogClosed = (status?: _ScheduledEmailStatus) => {
    setIsDialogOpen(false);
    if (status) {
      onChangeTab(status);
    }
  };
  const onSendNow = async (emailId: string): Promise<void> => {
    fireConfirmationDialog({
      title: trans('emails.send_now_confirm_title'),
      onConfirm: async () => {
        const emailData: Partial<_ScheduledEmail> = {
          emails: email.emails,
          email_text: email.email_text,
          email_subject: email.email_subject,
          action_link: email.action_link,
          action_text: email.action_text,
        };
        const success = await sendScheduledEmailAction(emailId, emailData);
        if (success) {
          snackbar.success(trans('emails.send_now_success'));
          reloadContentEngine();
          onChangeTab('sent');
        } else {
          snackbar.danger(trans('emails.send_now_error'));
        }
      },
    });
  };

  const onEdit = (emailData: _ScheduledEmail): void => {
    // Open the dialog with SendNewEmailDialogBody for editing
    setDialogTitle(trans('emails.edit_email'));
    setDialogContent(
      <SendNewEmailDialogBody
        profile={currentProfile()}
        subject={emailData.email_subject}
        body={emailData.email_text}
        recipients={emailData.emails}
        actionLink={emailData.action_link}
        actionText={emailData.action_text}
        status={emailData.status}
        scheduledFor={emailData.scheduled_at}
        emailId={emailData.id}
        isPreview={false}
        onClosed={onDialogClosed}
        readOnly
      />
    );
    setIsDialogOpen(true);
  };

  const onDelete = async (emailId: string): Promise<void> => {
    fireConfirmationDialog({
      title: trans('emails.delete_confirm_title'),
      onConfirm: async () => {
        const success = await deleteScheduledEmailAction(emailId);
        if (success) {
          snackbar.success(trans('emails.delete_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('emails.delete_error'));
        }
      },
    });
  };

  const onPreview = (emailData: _ScheduledEmail): void => {
    // Open the dialog with SendNewEmailDialogBody for previewing
    setDialogTitle(trans('emails.preview_email'));
    setDialogContent(
      <SendNewEmailDialogBody
        profile={currentProfile()}
        subject={emailData.email_subject}
        body={emailData.email_text}
        recipients={emailData.emails}
        actionLink={emailData.action_link}
        actionText={emailData.action_text}
        status={emailData.status}
        scheduledFor={emailData.scheduled_at}
        emailId={emailData.id}
        isPreview
        onClosed={onDialogClosed}
        readOnly
      />
    );
    setIsDialogOpen(true);
  };

  const getActions = (): Array<{label: string; onChange: () => void; icon: React.FC}> => {
    const actions = [];

    if (email.status === 'draft') {
      actions.push({
        label: trans('emails.actions.send_now'),
        onChange: async () => await onSendNow(email.id),
        icon: IconSend,
      });
      actions.push({
        label: trans('emails.actions.edit'),
        onChange: () => onEdit(email),
        icon: IconEditText,
      });
      actions.push({
        label: trans('emails.actions.delete'),
        onChange: async () => await onDelete(email.id),
        icon: IconTrash,
      });
    } else if (email.status === 'scheduled') {
      actions.push({
        label: trans('emails.actions.edit'),
        onChange: () => onEdit(email),
        icon: IconEditText,
      });
      actions.push({
        label: trans('emails.actions.delete'),
        onChange: async () => await onDelete(email.id),
        icon: IconTrash,
      });
    } else if (email.status === 'sent') {
      actions.push({
        label: trans('emails.actions.preview'),
        onChange: () => onPreview(email),
        icon: IconZoomIn,
      });
      actions.push({
        label: trans('emails.actions.delete'),
        onChange: async () => await onDelete(email.id),
        icon: IconTrash,
      });
    }

    return actions;
  };

  const getDateField = (): string => {
    // For draft show last edited date
    if (email.status === 'draft') {
      return trans('emails.last_edited_at', {
        date: formatDateTime(new Date(email.updated_at)),
      });
    }
    if (email.status === 'scheduled') {
      return trans('emails.scheduled_at', {
        date: formatDateTime(new Date(email.scheduled_at)),
      });
    }

    return trans('emails.sent_at', {
      date: formatDateTime(new Date(email.sent_at)),
    });
  };

  logger.debug('[EmailsOverviewCard] email', {
    isDialogOpen,
    dialogContent,
    dialogTitle,
  });
  return (
    <>
      <Card
        titleVariant="h6"
        titleClassName="w-full"
        titleSuffix={
          <div className="flex flex-row flex-grow justify-end">
            <Badge value={trans(statusLangProps[email.status])} color={colors[email.status]} size="fit" />
          </div>
        }
        title={email.email_subject}
        gap={0}
      >
        <div className="flex flex-row h-full w-full justify-between">
          <div className="flex flex-col w-full h-full gap-2">
            <Emails emails={email.emails} readOnly />

            <div dangerouslySetInnerHTML={{__html: email.email_text}} />

            <div>--</div>

            <div className="flex flex-row flex-grow gap-1">
              {trans('emails.recipients_count', {
                count: email.emails.length,
              })}
              <div>|</div>
              {getDateField()}
            </div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <ContextMenu triggerIcon={IconMore} menuItems={getActions()} />
          </div>
        </div>
      </Card>

      {isDialogOpen && (
        <Dialog
          size="lg"
          title={dialogTitle}
          open={isDialogOpen}
          showCloseButton
          onClose={() => setIsDialogOpen(false)}
        >
          {dialogContent}
        </Dialog>
      )}
    </>
  );
};
