// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {trans} from '@modules/Translations/util/i18n';

export const EmailOverviewPlaceholder: React.FC = () => {
  return (
    <Section
      imageViewDirection="vertical"
      description={trans('emails.placeholder.description')}
      image="/assets/img/design_images/new_email.png"
      justifyContent="center"
    />
  );
};
