// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {_Dialog} from '@modules/Core/components/base/Dialog';
import {IconEmail} from '@modules/Core/components/base/Icons/Icons';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {showEmailDialog, SendNewEmailDialog, EmailContent} from '@modules/Core/util/emailDialogHelper';
import {show} from '@modules/Core/util/snackbar';
import {trans} from '@modules/Translations/util/i18n';
import EmailForm from './EmailForm';

interface SendEmailProps {
  label?: string;
  showIcon?: boolean;
  emailContent: EmailContent;
  availableEmails?: string[];
}

const SendEmail: React.FC<SendEmailProps> = ({
  label = trans('base.send_email'),
  showIcon,
  emailContent,
  availableEmails,
}) => {
  const [dialogConfig, setDialogConfig] = useState<_Dialog>({});

  const onClose = () => {
    setDialogConfig({open: false});
  };

  return (
    <>
      <ToolTip txt={label}>
        <Button
          minified
          variant="light"
          size="xs"
          icon={IconEmail}
          onClick={() =>
            showEmailDialog({
              emailContent,
              setDialogConfig,
              onCloseDialog: onClose,
              availableEmails,
            })
          }
        />
      </ToolTip>

      <SendNewEmailDialog dialogConfig={dialogConfig} onClose={onClose} />
    </>
  );
};

export default SendEmail;
